import {
    GET_ORDER_LIST_SUCCESS,
    GET_ORDER_LIST_FAIL,
    GET_ORDER_SUCCESS,
    GET_ORDER_FAIL,
} from '../actions/orders/types';

const initialState = {
    order_list: null,
    order: null,
    order_lines: null
};

export default function orders(state = initialState, action) {
    const { type, payload } = action;

    switch(type) {
        case GET_ORDER_LIST_SUCCESS:
            return {
                ...state,
                order_list: payload.GRP2,
            }
        case GET_ORDER_LIST_FAIL:
            return {
                ...state,
                order_list: null,
            }
        case GET_ORDER_SUCCESS:
            return {
                ...state,
                order_lines: payload.GRP2
            }
        case GET_ORDER_FAIL:
            return {
                ...state,
                order_lines: null
            }
        default:
            return state
    }
}