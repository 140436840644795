import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { motion } from "framer-motion";
import Navbar from "components/navigation/Navbar";
import Footer from "components/navigation/Footer";
import {
  check_authenticated,
  load_user,
  //logout,
  refresh,
} from "redux/actions/auth/auth";
import InfinityLoader from "components/utils/InfinityLoader";

function Layout({
  children,
  refresh,
  check_authenticated,
  load_user,
  user_loading,
  isAuthenticated,
  user,
}) {
  const [loading, setLoading] = useState(true);
  const [animationFinished, setAnimationFinished] = useState(false);

  useEffect(() => {
    const initialize = async () => {
      await refresh();
      await check_authenticated();
      await load_user();
      setLoading(false);
    };
    initialize();
  }, [refresh, check_authenticated, load_user]);

  const handleAnimationFinish = () => {
    setAnimationFinished(true);
  };

  if (loading || !animationFinished) {
    return (
      <div className="flex justify-center items-center h-screen">
        <InfinityLoader onFinish={handleAnimationFinish} />
      </div>
    );
  }

  return (
      <motion.div
        initial={{ opacity: 0, transition: { duration: 0.5 } }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0, transition: { duration: 0.5 } }}
      >
        <div className="flex flex-col h-screen font-raleway">
          <div className="navbar">
            <Navbar />
          </div>
          {children}
          <div className="footer">
            <Footer />
          </div>
        </div>
      </motion.div>
  );
}

const mapStateToProps = (state) => ({
  user_loading: state.auth.user_loading,
  isAuthenticated: state.auth.isAuthenticated,
  user: state.auth.user,
});

export default connect(mapStateToProps, {
  refresh,
  check_authenticated,
  load_user,
})(Layout);
