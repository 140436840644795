import React, { useState } from "react";
import Select from "react-select";
import { components } from "react-select";
import { FaSearch } from "react-icons/fa"; // Asegúrate de instalar react-icons

const customStyles = {
  control: (base, state) => ({
    ...base,
    border: "none", // Quitar el borde del control
    boxShadow: "none", // Quitar la sombra del borde del control
    "&:hover": {
      border: "none", // Asegurarse de que el borde no aparezca al pasar el mouse
    },
    "&:focus": {
      border: "none", // Asegurarse de que el borde no aparezca al enfocar
    },   
    display: 'flex',
  }),
  input: (base) => ({
    ...base,
    color: "blue", // Color del texto en el input
    "& input": {
      boxShadow: "none !important", // Quitar la sombra del input
      border: "none !important", // Quitar el borde del input
      outline: "none !important", // Quitar el outline del input
    },
  }),
  singleValue: (base) => ({
    ...base,
    color: "blue", // Color del texto seleccionado
  }),
  placeholder: (base) => ({
    ...base,
    color: "blue", // Color del texto del placeholder
  }),
  menu: (base) => ({
    ...base,
    color: "blue", // Color del texto en el menú desplegable
  }),
  noOptionsMessage: (base) => ({
    ...base,
    color: "blue", // Color del texto cuando no hay opciones
  }),
};

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <FaSearch />
    </components.DropdownIndicator>
  );
};

const CustomSelect = ({ options, onChange }) => {
  const [selectedOption, setSelectedOption] = useState(null);

  const handleChange = (option) => {
    setSelectedOption(option);
    if (onChange) {
      onChange(option);
    }
  };

  return (
    <Select
      styles={customStyles}
      components={{ DropdownIndicator }}
      value={selectedOption}
      onChange={handleChange}
      options={options}
      isSearchable
      placeholder="Seleccione una opción..."
    />
  );
};

export default CustomSelect;
