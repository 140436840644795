import {
    ADD_TO_CART,
    DELETE_ALL_FROM_CART,
    DELETE_PRODUCT_FROM_CART,
    CALCULATE_TOTAL_PRICE_OF_THE_CART,
    INCREMENT_QUANTITY, 
    DECREMENT_QUANTITY
} from "./types"


export const addToCart = (articulo, cantidad) => async (dispatch, getState) => {
    const { cart, totalPriceShoppingCart } = getState().cart;

    // Verificar si el producto ya está en el carrito
    const existingProductIndex = cart.findIndex(product => product.COD_ARTICULO === articulo.COD_ARTICULO);

    if (existingProductIndex !== -1) {
        // Si el producto ya está en el carrito, actualiza la cantidad del producto existente
        const updatedCart = [...cart];
        updatedCart[existingProductIndex].cantidad = cantidad;

        dispatch({
            type: ADD_TO_CART,
            payload: { cart: updatedCart, totalPriceShoppingCart }
        });
    } else {
        // Si el producto no está en el carrito, agrega el producto al carrito con la cantidad especificada
        const newProduct = { ...articulo, cantidad };
        dispatch({
            type: ADD_TO_CART,
            payload: { cart: [...cart, newProduct], totalPriceShoppingCart }
        });
    }
};

export const subtractToCart = (articulo, cantidad) => async (dispatch, getState) => {
    const { cart, totalPriceShoppingCart } = getState().cart;

    // Verificar si el producto ya está en el carrito
    const existingProductIndex = cart.findIndex(product => product.COD_ARTICULO === articulo.COD_ARTICULO);

    if (existingProductIndex !== -1) {
        // Si el producto ya está en el carrito, actualiza la cantidad del producto existente
        const updatedCart = [...cart];
        updatedCart[existingProductIndex].cantidad = cantidad;

        dispatch({
            type: ADD_TO_CART,
            payload: { cart: updatedCart, totalPriceShoppingCart }
        });
    } else {
        // Si el producto no está en el carrito, agrega el producto al carrito con la cantidad especificada
        const newProduct = { ...articulo, cantidad };
        dispatch({
            type: ADD_TO_CART,
            payload: { cart: [...cart, newProduct], totalPriceShoppingCart }
        });
    }
};

export const deleteFromCart = (cod_articulo) => async (dispatch, getState) => {
    const { cart } = getState().cart;
    const updatedCart = cart.filter(item => !(item.COD_ARTICULO === cod_articulo || item.cantidad === 0));
    dispatch({
        type: DELETE_PRODUCT_FROM_CART,
        payload: updatedCart
    });
}

export const clearCart = () => async dispatch => {
    dispatch({
        type: DELETE_ALL_FROM_CART
    });
}

export const calculateTotalPriceOfCart = () => async dispatch => {
    dispatch({
        type: CALCULATE_TOTAL_PRICE_OF_THE_CART
    });
}

export const incrementQuantity = (productId) => ({
    type: INCREMENT_QUANTITY,
    payload: productId
});

export const decrementQuantity = (productId) => ({
    type: DECREMENT_QUANTITY,
    payload: productId
});