import React, { createContext, useState, useCallback, useEffect } from 'react';

export const HeightContext = createContext();

export const HeightProvider = ({ children }) => {
  const [navHeight, setNavHeight] = useState(0);
  const [footerHeight, setFooterHeight] = useState(0);

  const recalculateHeights = useCallback(() => {
    setNavHeight(document.querySelector('.navbar-class')?.clientHeight || 0);
    setFooterHeight(document.querySelector('.footer-class')?.clientHeight || 0);
  }, []);

  // Recalcular las alturas al cambiar el tamaño de la ventana
  useEffect(() => {
    window.addEventListener('resize', recalculateHeights);
    recalculateHeights(); // Inicialmente, calcular alturas

    return () => {
      window.removeEventListener('resize', recalculateHeights);
    };
  }, [recalculateHeights]);

  return (
    <HeightContext.Provider value={{ navHeight, setNavHeight, footerHeight, setFooterHeight, recalculateHeights }}>
      {children}
    </HeightContext.Provider>
  );
};
