import Layout from "hocs/layouts/Layout";
import { connect } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { TrashIcon } from "@heroicons/react/24/outline";
import { deleteFromCart, clearCart } from "redux/actions/Cart/cart";
import { useState, useEffect } from "react";
import { addToCart } from "redux/actions/Cart/cart";
import axios from "axios";
import xmlParser from "xml-js";
//import { css } from "@emotion/react";
import ClipLoader from "react-spinners/ClipLoader";
import ResumeCart from "components/cart/ResumeCart";
// import log from 'loglevel'

function Carrito({
  //data,
  carrito,
  deleteFromCart,
  clearCart,
  //image_product,
  //get_image_product,
  fondo_empresa,
  cliente,
}) {
  // log.setLevel('info');

  //const override = css`
  //  display: block;
  //  margin: 0 auto;
  //  border-color: red;
  //`;

  const [loading, setLoading] = useState(false);
  const [cantidades, setCantidades] = useState(
    carrito.map((producto) => producto.cantidad)
  );

  useEffect(() => {
    if (carrito) {
      setCantidades(carrito.map((producto) => producto.cantidad));
    }
  }, [carrito]);

  // const calculateTotal = () => {
  //   return carrito.reduce(
  //     (total, product, index) => total + product.PRECION * cantidades[index],
  //     0
  //   );
  // };

  const handleIncrement = (index) => {
    const newCantidades = [...cantidades];
    newCantidades[index] += 1;
    setCantidades(newCantidades);

    const updatedCarrito = [...carrito];
    updatedCarrito[index].cantidad += 1;
    addToCart(updatedCarrito[index], 1); // Despachar la acción para actualizar la cantidad en el carrito
  };

  const handleDecrement = (index) => {
    const newCantidades = [...cantidades];
    newCantidades[index] = Math.max(newCantidades[index] - 1, 0);
    setCantidades(newCantidades);

    if (newCantidades[index] === 0) {
      // Eliminar el producto del carrito
      deleteFromCart(carrito[index].COD_ARTICULO);
      // Eliminar la cantidad en el array cantidades
      const newCarrito = [...carrito];
      newCarrito.splice(index, 1); // Esto elimina el elemento del carrito
      const newCantidadesFiltered = newCantidades.filter((_, i) => i !== index);
      setCantidades(newCantidadesFiltered); // Actualiza el estado de cantidades sin el producto eliminado
    } else {
      const updatedCarrito = [...carrito];
      updatedCarrito[index].cantidad = newCantidades[index];
      addToCart(updatedCarrito[index], -1); // Decrementa la cantidad del producto en el carrito
    }
  };

  const handleDelete = (cod_articulo) => {
    const index = carrito.findIndex(
      (item) => item.COD_ARTICULO === cod_articulo
    );
    if (index !== -1) {
      deleteFromCart(cod_articulo); // Llama a la acción deleteFromCart para eliminar el producto del carrito
      const newCantidades = [...cantidades];
      newCantidades.splice(index, 1); // Elimina la cantidad correspondiente
      setCantidades(newCantidades); // Actualiza el estado de cantidades
    }
  };

  const handleClear = () => {
    clearCart(); // Llama a la acción clearCart para limpiar el carrito
    setCantidades([]); // Limpia el array de cantidades
  };

  const handleSoapResponse = async (json) => {
    const messages = json["soapenv:Envelope"]["soapenv:Body"]["multiRef"];

    if (messages && messages.length > 0) {
      let orderCreated = false;
      let orderNumber = "";
      let errMessage = "";
      let warMessage = "";

      messages.forEach((msgDetail) => {
        if (msgDetail && msgDetail.message && msgDetail.message._text) {
          const messageText = msgDetail.message._text;
          const messageType = msgDetail.type._text;

          if (messageText.trim() !== "") {
            // console.log(`Mensaje [Tipo ${messageType}]: ${messageText}`);

            // Detecta mensajes de creación de pedido
            if (messageType === "1" && messageText.includes("Creación de ")) {
              orderCreated = true;
              orderNumber = messageText.split(" ")[2]; // Asume que el número del pedido sigue después de 'Creación de '
            } else if (messageType === "2") {
              warMessage = messageText;
            } else if (messageType === "3") {
              errMessage = messageText;
            }
          } else {
            console.log(
              `Mensaje vacío o no disponible para el tipo ${messageType}`
            );
          }
        } else {
          console.log("Detalle del mensaje no disponible o mal formateado");
        }
      });

      // Si se creó un pedido, limpia el carrito y notifica al usuario
      if (orderCreated) {
        clearCart(); // Llama a la acción clearCart para limpiar el carrito
        setCantidades([]);
        toast.success(`Pedido ${orderNumber} creado exitosamente.`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error(`${errMessage}`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } else {
      console.log("No hay mensajes para mostrar.");
    }
  };

  const realizarPedido = async (planta, cliente, fecha) => {
    // const xmlHeader = 'E;ES012;SON;;AO006;20240512;PRUEBA;ES012;EUR;;;;;;1';
    const xmlHeader = `E;${planta};SON;;${cliente};${fecha};PRUEBA;${planta};EUR;;;;;;1`;
    const xmlLines = carrito
      .map(
        (item, index) =>
          `L;${item.COD_ARTICULO};A;${item.DESCRIPCION};${item.UNIDAD_MEDIDA};${item.cantidad};${item.PRECIOB};;;|`
      )
      .join("");
    const xmlFooter = "END";
    const xmlFile = `${xmlHeader}|${xmlLines}${xmlFooter}`;

    console.log(xmlFile);

    const body = `<soapenv:Envelope xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:wss="http://www.adonix.com/WSS">
                    <soapenv:Header/>
                    <soapenv:Body>
                      <wss:run soapenv:encodingStyle="http://schemas.xmlsoap.org/soap/encoding/">
                        <callContext xsi:type="wss:CAdxCallContext">
                            <codeLang xsi:type="xsd:string">SPA</codeLang>
                            <poolAlias xsi:type="xsd:string">ADC_SEED</poolAlias>
                            <poolId xsi:type="xsd:string">ADC_SEED</poolId>
                            <requestConfig xsi:type="xsd:string">adxwss.trace.on=off&amp;adxwss.beautify=true&amp;adxwss.optreturn=JSON</requestConfig>  
                        </callContext>    
                        <publicName xsi:type="xsd:string">AOWSIMPORT</publicName>
                        <inputXml xsi:type="xsd:string">
                          <![CDATA[<?xml version="1.0" encoding="UTF-8"?>
                            <PARAM>
                              <GRP ID="GRP1">        
                                  <FLD NAME="I_MODIMP">YSOH</FLD>
                                  <FLD NAME="I_AOWSTA">NO</FLD>
                                  <FLD NAME="I_EXEC">REALTIME</FLD>
                                  <FLD NAME="I_RECORDSEP">|</FLD>
                                  <FLD NAME="I_FILE">${xmlFile}</FLD>
                              </GRP>
                            </PARAM>
                          ]]>
                        </inputXml>
                      </wss:run>
                    </soapenv:Body>
                  </soapenv:Envelope>`;

    setLoading(true); // Activar el spinner antes de la solicitud
    try {
      const config = {
        headers: {
          "Content-Type": "text/xml",
          SOAPAction: "#POST",
        },
        auth: {
          username: `${process.env.REACT_APP_USER_SAGE}`,
          password: `${process.env.REACT_APP_PWD_SAGE}`,
        },
      };

      const response = await axios.post(
        `${process.env.REACT_APP_SAGE_API_URL}`,
        body,
        config
      );
      if (response.data) {
        // console.log("Data received", response.data);
        const jsonPayload = xmlParser.xml2js(response.data, {
          compact: true,
          spaces: 4,
          ignoreComment: true,
          alwaysChildren: true,
        });
        await handleSoapResponse(jsonPayload);
      } else {
        console.log("No XML data received.");
      }
      setLoading(false);
    } catch (error) {
      console.log("Error en la petición:", error);
      if (error.response) {
        // La petición fue hecha y el servidor respondió con un código de estado
        // que cae fuera del rango de 2xx
        console.log(
          "Error en la respuesta del servidor:",
          error.response.status
        );
        console.log("Detalles del error:", error.response.data);
        console.log(
          "Error en el servidor: " + error.response.data.$diagnoses[0].$message
        );
      } else if (error.request) {
        // La petición fue hecha pero no se recibió respuesta
        console.log("No se recibió respuesta:", error.request);
      } else {
        // Algo falló al hacer la petición
        console.log("Error al hacer la petición:", error.message);
      }
      setLoading(false);
    } finally {
      setLoading(false);
    }
  };

  const [popupOpen, setPopupOpen] = useState(false);

  const togglePopup = () => {
    setPopupOpen(!popupOpen);
  };

  const realizarPedidoHandler = () => {
    togglePopup();
    // if (!carrito.length) return;  // Verifica que el carrito no esté vacío

    // const fecha = new Date().toISOString().slice(0, 10).replace(/-/g, "");

    // setLoading(true);  // Inicia el indicador de carga
    // try {
    //   await realizarPedido(planta, cliente, fecha);  // Espera a que realizarPedido se complete
    // } catch (error) {
    //   // console.log('Error realizando el pedido:', error);
    //   toast.error('Error al realizar el pedido: ' + error.message);  // Muestra un mensaje de error
    // } finally {
    //   setLoading(false);  // Asegura que el estado de carga se desactive
    // }
  };

  return (
    <Layout>
      <div
        className="flex flex-col h-screen items-center justify-center bg-cover relative"
        style={{
          backgroundImage: `url(${fondo_empresa})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        {/*  <div className="absolute bg-white opacity-70 w-full text-center h-[30em]"></div>*/}
        <div className="w-full mx-auto">
          <div className="relative">
            <div className="flex justify-center items-center">
              <div className="w-full h-[70vh] md:w-3/4 md:h-[70vh] overflow-y-auto hide-scrollbar">
                <h1 className="text-pink-500 font-semibold mb-4 text-left text-4xl ml-2">
                  Carrito
                </h1>
                <div className="relative bg-white bg-opacity-70 shadow-md border-2 border-pink-300 ml-2 mr-2 rounded-lg">
                  {/*<div className="md:max-h-[28vh] xs:max-h-[30vh] w-3/4 mt-4 md:w-full">*/}
                  <div className="flex justify-end mt-0">
                    {carrito.length > 0 && (
                      <button
                        onClick={handleClear}
                        className="rounded-md border-2 border-pink-600 m-2 p-2 font-medium text-pink-600 h-12 mr-2"
                      >
                        Limpiar
                      </button>
                    )}
                  </div>
                  {carrito &&
                    carrito.map((product, index) => (
                      <div
                        key={index}
                        className="flex flex-col  md:flex-row rounded-xl bg-clip-border md:w-11/12"
                      >
                        <div className="w-full mb-4 md:ml-10 flex items-center justify-center">
                          <div className="flex justify-between mb-0 p-2 rounded-lg bg-white border-2 border-pink-200 md:p-2 sm:w-full md:items-center">
                            <img
                              src={`data:image/jpeg;base64,${product.image}`}
                              alt={product.DESCRIPCION}
                              className="w-20 md:w-32 lg:w-40 object-contain sm:mb-2"
                            />
                            <div className="flex flex-col w-full sm:justify-between sm:flex-row sm:ml-4">
                              <div className="mt-2 sm:w-40 lg:w-72 sm:mt-0 space-y-2">
                                <h2 className="text-sm sm:text-base lg:text-lg font-bold text-gray-900">
                                  {product.COD_ARTICULO}
                                </h2>
                                <p className="mt-1 text-sm sm:text-base lg:text-lg text-gray-700">
                                  {product.DESCRIPCION}
                                </p>
                                <div className="flex flex-row space-x-4">
                                  <p className="text-sm sm:text-base lg:text-lg">
                                    {product.UNIDAD_MEDIDA}
                                  </p>
                                  <p className="text-sm sm:text-base lg:text-lg">
                                    Precio:{" "}
                                    {parseFloat(product.PRECION).toFixed(2)} €
                                  </p>
                                </div>
                              </div>
                              <div className="mt-4 flex flex-col space-y-2 justify-between mb-0 sm:space-y-6 sm:mt-0 sm:block sm:space-x-6 ">
                                <div className="flex items-center border-gray-100 overflow-auto">
                                  <button
                                    className="cursor-pointer rounded-l bg-pink-200 py-1 px-3.5 sm:px-3 duration-100 hover:bg-pink-500 hover:text-pink-50"
                                    onClick={() => handleDecrement(index)}
                                  >
                                    {" "}
                                    -{" "}
                                  </button>
                                  <input
                                    className="h-8 w-12 sm:w-10 border-pink-300 bg-white text-center text-sm sm:text-xs outline-none"
                                    value={cantidades[index]}
                                    min="0"
                                    readOnly
                                  />
                                  <button
                                    className="cursor-pointer rounded-r sm:px-3 bg-pink-200 py-1 px-3 duration-100 hover:bg-pink-500 hover:text-pink-50"
                                    onClick={() => handleIncrement(index)}
                                  >
                                    {" "}
                                    +{" "}
                                  </button>
                                  <button
                                    className="focus:outline-none ml-8"
                                    onClick={() =>
                                      handleDelete(product.COD_ARTICULO)
                                    }
                                  >
                                    <TrashIcon className="w-6 h-6 text-pink-600" />
                                  </button>
                                </div>
                                <div className="flex items-end">
                                  <h2 className="text-sm sm:text-base lg:text-lg font-semibold">
                                    Subtotal:{" "}
                                    {(
                                      product.PRECION * cantidades[index]
                                    ).toFixed(2)}{" "}
                                    €
                                  </h2>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  {/*</div>*/}
                  {carrito.length > 0 && (
                    <div>
                      <div className="flex flex-col justify-center items-end space-y-4 mt-10 mr-4 m-10 md:mr-20 ">
                        {/* <h1 className="text-sm sm:text-base lg:text-xl font-bold">
                          Total: {calculateTotal().toFixed(2)} €
                        </h1> */}
                        <button
                          onClick={() =>
                            realizarPedidoHandler(
                              "ES012",
                              cliente?.cliente_sage
                            )
                          }
                          className="rounded-lg bg-pink-500 hover:bg-pink-600 text-white px-4 py-2 text-sm sm:text-base lg:text-lg"
                          disabled={loading || !carrito.length} // Deshabilita el botón cuando se está cargando o el carrito está vacío
                        >
                          Revisar pedido
                        </button>
                      </div>
                      {/* <div className="flex justify-end space-x-4 mb-2 mx-2 mt-10"> */}
                      {/* <button className="text-pink-600 border-2 border-pink-600 rounded-lg px-4 py-2 text-sm">Cancelar</button> */}

                      {/* </div> */}
                    </div>
                  )}
                  {carrito.length === 0 && (
                    <div className="flex justify-center items-center h-80 mt-10">
                      <p className="text-gray-500">Tu carrito está vacío</p>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {loading && (
        <div className="fixed top-0 left-0 z-50 w-screen h-screen flex items-center justify-center bg-black bg-opacity-50">
          <ClipLoader color="#FFFFFF" loading={loading} size={150} />
        </div>
      )}
      <ToastContainer />
      {/* Pop-up de detalles del pedido */}
      {popupOpen && 
        <ResumeCart togglePopup={togglePopup} carrito={carrito} planta="ES016" />
      }
    </Layout>
  );
}

const mapStateToProps = (state) => ({
  carrito: state.cart.cart,
  image_product: state.products.image_product,
  fondo_empresa: state.auth.empresa.imagen_background,
  cliente: state.auth.user,
});

export default connect(mapStateToProps, {
  deleteFromCart,
  clearCart,
})(Carrito);
