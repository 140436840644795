import { combineReducers } from 'redux'
import orders from './orders'
import auth from './auth'
import products from './products'
import { cart } from './cart'
import contact from './contact'

export default combineReducers({
    auth,
    orders,
    products,
    cart,
    contact
})