import { connect } from "react-redux"
import { ChatBubbleLeftEllipsisIcon, KeyIcon, AtSymbolIcon } from "@heroicons/react/24/outline"
import infinityLogo from 'assets/img/Infinity3_logo.png'
import { useEffect,useState } from "react"
import {  check_authenticated, load_user, login, refresh } from "redux/actions/auth/auth";
import { Navigate } from "react-router-dom";

function Home({
    login,
    isAuthenticated,
    loading,
    refresh,
    check_authenticated,
    load_user,
    nombre_empresa,
}) {

    const [userLoaded, setUserLoaded] = useState(false);

    useEffect(() => {
        if (isAuthenticated) {
            refresh();
            check_authenticated();
            load_user().then(() => setUserLoaded(true));
        }
    }, [isAuthenticated, refresh, check_authenticated, load_user]);

    const MESSAGE_DISPLAY_TIME = 5000; // 5 segundos 
    const [showMessage, setShowMessage] = useState(false); // Estado para controlar la visibilidad del mensaje

    const [formData, setFormData] = useState({
        username: '',
        password: ''
    });

    const {
        username,
        password
    } = formData;

    const onChange = e => setFormData({ ...formData, [e.target.name]: e.target.value });


    const onSubmit = async (e) => {
        e.preventDefault();
        await login(username, password);
        console.log(isAuthenticated)
        if (!isAuthenticated) {
            setShowMessage(true);
            setTimeout(() => {
                setShowMessage(false);
            }, MESSAGE_DISPLAY_TIME);
        }
    }

    if (isAuthenticated && userLoaded) {
        return <Navigate to={`/${nombre_empresa}/dashboard`} />
    }

    return (
        <div className="flex justify-center items-center w-full h-screen bg-infinity bg-cover bg-center">
            <div className="shrink items-center justify-center bg-white rounded-3xl bg-opacity-85 w-3/4 h-auto sm:w-2/4 lg:w-2/5 2xl:h-3/6">
                <div className="space-y-8 h-full">
                    <div className="">
                        <div className="p-4">
                            <img
                                className="mx-auto h-auto w-40"
                                src={infinityLogo}
                                alt="SANYCCES"
                            />
                        </div>
                        <div className="w-full text-center">
                            {/*ACA VAN LAS BANDERAS DE SELECCION DE IDIOMA*/}
                        </div>
                        <form onSubmit={e => { onSubmit(e) }} className="mt-6 space-y-8 p-8 px-12 " action="#" method="POST">
                            <div className="space-y-6">
                                <div className="relative">
                                    <label htmlFor="username" className="sr-only">
                                        Nombre de usuario
                                    </label>
                                    <AtSymbolIcon className="pointer-events-none absolute inset-y-2 left-0 flex items-center h-5 w-5 text-sany-button-primary" />
                                    <input
                                        id="username"
                                        name="username"
                                        value={username}
                                        onChange={e => onChange(e)}
                                        type="text"
                                        required
                                        autoComplete="username"
                                        className="pl-12 relative block w-full appearance-none rounded-none border-0 border-b-2 bg-transparent border-sany-button-secondary px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:ring-0 focus:border-sany-button-primary focus:outline-none sm:text-sm"
                                        placeholder="Usuario"
                                    />
                                </div>
                                <div className="relative">
                                    <label htmlFor="password" className="sr-only ">
                                        Contraseña
                                    </label>
                                    <KeyIcon className="pointer-events-none absolute inset-y-2 left-0 flex items-center h-5 w-5 text-sany-button-primary" />
                                    <input
                                        id="password"
                                        name="password"
                                        value={password}
                                        onChange={e => onChange(e)}
                                        type="password"
                                        required
                                        autoComplete="current-password"
                                        className="pl-12 relative block w-full appearance-none rounded-none border-0 border-b-2 bg-transparent border-sany-button-secondary px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:ring-0 focus:border-sany-button-primary focus:outline-none sm:text-sm"
                                        placeholder="Contraseña"
                                    />

                                </div>
                                <div className="flex items-center justify-center pt-4">
                                    <div className={`flex items-center ${showMessage ? 'visible' : 'hidden'}`}>
                                        <ChatBubbleLeftEllipsisIcon className="h-5 w-5 text-sany-button-primary" aria-hidden="true" />
                                        <span className="ml-2 block text-sm text-red-500">
                                            Login incorrecto
                                        </span>
                                    </div>
                                </div>

                                <div className="flex items-center justify-center">
                                    <button
                                        type="submit"
                                        className="group relative flex w-1/2 justify-center rounded-md border border-transparent bg-gradient-to-b from-sany-button-primary to-sany-button-secondary py-2 px-4 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                    >
                                        Iniciar sesión
                                    </button>
                                </div>
                            </div>


                        </form>
                    </div>
                </div>
            </div>
            {/* <Footer /> */}
        </div>
    )
}

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
    loading: state.auth.loading,
    nombre_empresa: state.auth.empresa?.nombre.toLowerCase()
})

export default connect(mapStateToProps, {
    login,
    refresh,
    check_authenticated,
    load_user,
})(Home)