import { BrowserRouter as Router } from "react-router-dom";
import store from "./store";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Provider } from "react-redux";
import AnimatedRoutes from "hocs/routes/Routes";
import { HeightProvider } from "components/utils/HeightContext";

function App() {
  return (
    <HelmetProvider>
      <Helmet>
        <title>Infinity3 | Dashboard</title>
        <meta name="description" content="" />
        <meta name="keywords" content="" />
        <meta name="robots" content="all" />
        <link rel="canonical" href="https://www.inifity3.com/" />
        <meta name="author" content="Nunsys Sage" />
        <meta name="publisher" content="Nunsys Sage" />

        {/* Social Media Tags */}
        <meta property="og:title" content="Infinity3 | Nunsys" />
        <meta property="og:description" content="" />
        <meta property="og:url" content="https://www.inifity3.com/" />
        <meta property="og:image" content="" />

        <meta name="twitter:title" content="Infinity3 | Nunsys" />
        <meta name="twitter:description" content="" />
        <meta name="twitter:image" content="" />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <Provider store={store}>
        <HeightProvider>
          <Router>
            <AnimatedRoutes />
          </Router>
        </HeightProvider>
      </Provider>
    </HelmetProvider>
  );
}

export default App;
