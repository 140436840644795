import React from 'react';
import { Navigate } from 'react-router-dom';
import { connect } from 'react-redux';

const ProtectedRoute = ({ isAuthenticated, children, loading }) => {
    if (loading) {
        return (
            <div className="flex justify-center items-center h-screen">
                <div className="loader">
                    
                </div>
            </div>
        );
    }

    if (!isAuthenticated) {
        return <Navigate to="/" />;
    }

    return children;
};

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
    loading: state.auth.auth_loading || state.auth.user_loading
});

export default connect(mapStateToProps)(ProtectedRoute);
