import { connect } from "react-redux";
import { Popover, Transition } from "@headlessui/react";
import React, { useRef, useContext, Fragment, useLayoutEffect  } from "react";
import { NavLink, Link, useNavigate } from "react-router-dom";
import { HeightContext } from "components/utils/HeightContext";
import {
  UserCircleIcon,
  Bars3Icon,
  XMarkIcon,
  ShoppingCartIcon,
} from "@heroicons/react/24/outline";
import { logout } from "redux/actions/auth/auth";

function Navbar({ contacto, carrito, logout, logo_empresa, nombre_empresa }) {
  const navRef = useRef(null);
  const { setNavHeight, recalculateHeights } = useContext(HeightContext);

  useLayoutEffect (() => {
    if (navRef.current) {
      setNavHeight(navRef.current.clientHeight);
    }
    recalculateHeights(); // Asegura recalcular las alturas cuando se monta el componente
  }, [navRef, setNavHeight, recalculateHeights]);

  window.onscroll = function () {
    scrollFunction();
  };

  function scrollFunction() {
    if (document.getElementById("navbar")) {
      if (
        document.body.scrollTop > 50 ||
        document.documentElement.scrollTop > 50
      ) {
        document.getElementById("navbar").classList.add("shadow-navbar");
        document.getElementById("navbar").classList.add("bg-white");
      } else {
        document.getElementById("navbar").classList.remove("shadow-navbar");
        document.getElementById("navbar").classList.remove("bg-white");
      }
    }
  }

  const solutions = [
    {
      name: "Mis pedidos",
      description: "Create your own targeted content",
      href: `/${nombre_empresa}/mis-pedidos`,
      icon: IconTwo,
    },
    {
      name: "Nuevo pedido",
      description: "Keep track of your growth",
      href: `/${nombre_empresa}/nuevo-pedido`,
      icon: IconThree,
    },
    {
      name: "Carrito",
      description: "Keep track of your growth",
      href: `/${nombre_empresa}/carrito`,
      icon: IconThree,
    },
  ];

  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
    navigate("/login");
  };

  const totalArticulos = carrito.length;

  return (
    <nav ref={navRef} className="navbar-class absolute w-full bg-white opacity-90  py-4 shadow-navbar z-50 rounded-b-lg border-b-2 border-pink-400">
      <div className="px-4 sm:px-6">
        <div className="-ml-4 -mt-2 hidden lg:flex flex-wrap items-center justify-between sm:flex-nowrap md:px-14 px-2">
          <Link to={`/${nombre_empresa}/dashboard`} className="ml-4 mt-2">
            <img
              src={logo_empresa}
              alt="Logo empresa"
              className="h-auto w-28"
            />
          </Link>
          <div className="ml-auto flex items-center tracking-wider">
            {/* <NavLink to='/mis-datos' className="text-lg inline-flex font-medium leading-6 text-sany-color border-b-2 border-sany-button-secondary  hover:border-sany-button-primary transition duration-300 ease-in-out mx-8">Mis datos</NavLink> */}
            <NavLink
              to={`/${nombre_empresa}/mis-pedidos`}
              className="text-lg inline-flex font-medium leading-6 text-pink-500 border-b-2 border-pink-500 hover:border-pink-600/30  transition duration-300  ease-in-out mx-8"
            >
              Mis pedidos
            </NavLink>
            <NavLink
              to={`/${nombre_empresa}/nuevo-pedido`}
              className="text-lg inline-flex font-medium leading-6 text-pink-500 border-b-2 border-pink-500 hover:border-pink-600/30 transition duration-300 ease-in-out mx-8"
            >
              Nuevo pedido
            </NavLink>
            <div className="flex items-center space-x-10">
              <Link
                to={`/${nombre_empresa}/carrito`}
                className="inline-flex ml-12 items-center rounded-md border border-transparent px-2 py-3 text-base font-medium text-gray-700 hover:text-sany-button-primary relative"
              >
                <ShoppingCartIcon className="h-auto w-8 text-pink-600" />
                {totalArticulos > 0 && (
                  <span className="bg-pink-500 text-white rounded-full w-5 h-5 flex items-center justify-center absolute top-5 right-0 transform translate-x-1/2 -translate-y-1/2 text-xs">
                    {totalArticulos}
                  </span>
                )}
              </Link>

              {/*  <Link
                            to="/logout"
                            className="inline-flex ml-12 items-center rounded-md border border-transparent bg-orange-button px-2 py-3 text-base font-medium text-gray-700 shadow-sm hover:text-sany-button-primary transition duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-orange-500 focus:ring-offset-2"
                        >
                            <UserCircleIcon className='absolute h-auto w-8 pb-4 transform mt-8' />
                        </Link>*/}
              <Popover className="relative">
                {({ open }) => (
                  <>
                    <Popover.Button className="ml-4 mt-2">
                      {contacto?.imagen_usuario ? (
                        <img
                          src={`${contacto.imagen_usuario}`}
                          alt="User"
                          className="h-12 w-12 border-2 border-pink-600 rounded-full object-contain"
                        />
                      ) : (
                        <UserCircleIcon className="h-auto w-8 text-pink-600 transition duration-300 ease-in-out" />
                      )}
                    </Popover.Button>
                    <Transition
                      as={Fragment}
                      enter="transition ease-out duration-200"
                      enterFrom="opacity-0 translate-y-1"
                      enterTo="opacity-100 translate-y-0"
                      leave="transition ease-in duration-150"
                      leaveFrom="opacity-100 translate-y-0"
                      leaveTo="opacity-0 translate-y-1"
                    >
                      <Popover.Panel className="absolute z-10 w-48 mt-2 origin-top-right right-0 shadow-lg rounded-md ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-gray-200 ring-opacity-5">
                          <div className="relative grid gap-8 bg-white p-2 border-2 border-pink-300">
                            <div className="flex flex-col items-center -mb-4">
                              <p className="text-pink-600 ml-12">
                                {contacto && contacto.cliente_sage}
                              </p>
                            </div>
                            <div className="flex justify-left -mb-4 ">
                              <p>{contacto && contacto.email}</p>
                            </div>
                            <div className="flex justify-left -mb-4 ">
                              <p>{contacto && contacto.telefono}</p>
                            </div>
                            <div className="flex justify-left -mb-4">
                              <p className="text-sm font-medium text-gray-500">
                                {contacto && contacto.direccion}
                              </p>
                            </div>
                            <div className="flex justify-center border-t-2 border-pink-300">
                              <button
                                onClick={() => handleLogout()}
                                className="text-pink-500 font-semibold hover:text-pink-600"
                              >
                                Cerrar sesion
                              </button>
                            </div>
                          </div>
                        </div>
                      </Popover.Panel>
                    </Transition>
                  </>
                )}
              </Popover>
            </div>
          </div>
        </div>

        <div className="-ml-4 -mt-2 lg:hidden flex flex-wrap items-center justify-between sm:flex-nowrap md:px-14 px-2">
          <Link to={`/${nombre_empresa}/dashboard`} className="ml-4 mt-2">
            <img
              src={logo_empresa}
              alt="Logo empresa"
              className="h-auto w-28"
            />
          </Link>
          <div className="ml-4 mt-2 flex-shrink-0">
            <Popover className="relative">
              {({ open }) => (
                <>
                  <Popover.Button
                    className={` 
                                ${open ? "" : "text-opacity-90"}
                                focus:ring-none focus:outline-none`}
                  >
                    {open ? (
                      <XMarkIcon className="h-auto w-10" />
                    ) : (
                      <Bars3Icon className="h-auto w-10" />
                    )}
                  </Popover.Button>

                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-200"
                    enterFrom="opacity-0 translate-y-1"
                    enterTo="opacity-100 translate-y-0"
                    leave="transition ease-in duration-150"
                    leaveFrom="opacity-100 translate-y-0"
                    leaveTo="opacity-0 translate-y-1"
                  >
                    <Popover.Panel className="absolute -left-32 z-10 mt-3 w-screen max-w-sm -translate-x-1/2 transform px-4 sm:px-0 lg:max-w-3xl">
                      <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-gray-200 ring-opacity-5">
                        <div className="relative grid gap-8 bg-white p-2 lg:grid-cols-2">
                          <div className="p-4 border border-pink-300">
                            <div className="flex justify-end">
                              <p className="font-semibold text-pink-600 ">
                                Mis datos
                              </p>
                            </div>
                            <div className="ml-4">
                              <p className="text-sm font-medium text-pink-600">
                                Email
                              </p>
                              <p className="text-sm font-medium text-gray-500">
                                {contacto && contacto.email}
                              </p>
                            </div>
                            <div className="flex flex-row mt-4">
                              <div className="ml-4">
                                <p className="text-sm font-medium text-pink-600">
                                  Telefono
                                </p>
                                <p className="text-sm font-medium text-gray-500">
                                  {contacto && contacto.telefono}
                                </p>
                              </div>
                              <div className="ml-4">
                                <p className="text-sm font-medium text-pink-600">
                                  Direccion
                                </p>
                                <p className="text-sm font-medium text-gray-500">
                                  {contacto && contacto.direccion}
                                </p>
                              </div>
                            </div>
                          </div>
                          <div className="">
                            <p className="font-semibold text-pink-600 text-xl">
                              Menu
                            </p>
                          </div>
                          {solutions.map((item) => (
                            <Link
                              key={item.name}
                              to={item.href}
                              className="-m-3 flex items-center rounded-lg p-2 duration-100 hover:bg-pink-400 "
                            >
                              {/* <div className="flex h-10 w-10 shrink-0 items-center justify-center text-black sm:h-12 sm:w-12">
                                                                <item.icon aria-hidden="true" iconos de la izq/>
                                                            </div> */}
                              <div className="ml-2">
                                <p className="text-sm font-semibold text-gray-700">
                                  {item.name}
                                </p>
                              </div>
                            </Link>
                          ))}
                        </div>
                        <div className="bg-white border-2 border-pink-300 p-4 rounded-md">
                          <button
                            onClick={() => handleLogout()}
                            className="flow-root rounded-md px-2 py-2"
                          >
                            <span className="flex justify-center">
                              <span className="text-sm items-center font-medium text-pink-600 ">
                                Cerrar sesion
                              </span>
                            </span>
                          </button>
                        </div>
                      </div>
                    </Popover.Panel>
                  </Transition>
                </>
              )}
            </Popover>
          </div>
        </div>
      </div>
    </nav>
  );
}

const mapStateToProps = (state) => ({
  contacto: state.auth.user,
  carrito: state.cart.cart,
  logo_empresa: state.auth.empresa.logo,
  nombre_empresa: state.auth.empresa.nombre.toLowerCase(),
});

export default connect(mapStateToProps, {
  logout,
})(Navbar);

// function IconOne() {
//   return <></>;
// }

function IconTwo() {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="48" height="48" rx="8" fill="#FFEDD5" />
      <path
        d="M28.0413 20L23.9998 13L19.9585 20M32.0828 27.0001L36.1242 34H28.0415M19.9585 34H11.8755L15.9171 27"
        stroke="#FB923C"
        strokeWidth="2"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.804 30H29.1963L24.0001 21L18.804 30Z"
        stroke="#FDBA74"
        strokeWidth="2"
      />
    </svg>
  );
}

function IconThree() {
  return (
    <svg
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="48" height="48" rx="8" fill="#FFEDD5" />
      <rect x="13" y="32" width="2" height="4" fill="#FDBA74" />
      <rect x="17" y="28" width="2" height="8" fill="#FDBA74" />
      <rect x="21" y="24" width="2" height="12" fill="#FDBA74" />
      <rect x="25" y="20" width="2" height="16" fill="#FDBA74" />
      <rect x="29" y="16" width="2" height="20" fill="#FB923C" />
      <rect x="33" y="12" width="2" height="24" fill="#FB923C" />
    </svg>
  );
}
