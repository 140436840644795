import React from 'react';
import ProductCard from "./ProductCard";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";

const ProductCardMemo = React.memo(ProductCard);

function ProductList({ products }) {
  return (
      <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 500: 1, 700: 2, 1000: 3, 1500: 4 }}>
        <Masonry className="">
          {products && products.map((product, index) => (
            <div key={index} id={product.COD_ARTICULO}>
              <ProductCardMemo data={product} />
            </div> 
          ))}
        </Masonry>
      </ResponsiveMasonry>     
  );
}

export default ProductList;
