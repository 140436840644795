import React, { useEffect, useState } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import { check_authenticated, load_user, refresh } from 'redux/actions/auth/auth';
import Error404 from 'containers/errors/Error404';
import Home from 'containers/pages/Home';
import Dashboard from 'containers/pages/Dashboard';
import MisPedidos from 'containers/pages/MisPedidos';
import NuevoPedido from 'containers/pages/NuevoPedido';
import Carrito from 'containers/pages/Carrito';
import LandingPage from 'containers/pages/LandingPage'
import ProtectedRoute from './ProtectedRoute';  // Ajusta la ruta si es necesario
// import log from 'loglevel'

function useFontSize() {
    const location = useLocation();

    useEffect(() => {
        // Ajustar el tamaño de la fuente según la ruta
        if (location.pathname === '/') {
            document.documentElement.style.fontSize = '10px';
        } else {
            document.documentElement.style.fontSize = '16px';
        }

        // Limpiar el efecto al desmontar el componente
        return () => {
            document.documentElement.style.fontSize = '16px'; // Valor por defecto
        };
    }, [location]);
}

function AnimatedRoutes({
    isAuthenticated,
    refresh,
    check_authenticated,
    load_user,
    nombre_empresa,
}) {
    // log.setLevel('silent');
    
    const [loading, setLoading] = useState(true);
    const location = useLocation();

    useFontSize();

    useEffect(() => {
        const initialize = async () => {
            // console.log('Refreshing token...');
            await refresh();

            // console.log('Checking authentication...');
            await check_authenticated();

            // console.log('Loading user...');
            await load_user();

            setLoading(false);
        };
        initialize();

    }, [refresh, check_authenticated, load_user]);

    if (loading) {
        return (
            <div className="flex justify-center items-center h-screen">
                <div className="loader">
                    
                </div>
            </div>
        );
    }

    return (
        <Routes location={location} key={location.pathname}>
            {/* Error Display */}
            <Route path="*" element={<Error404 />} />

            {/* Home Display */}
            <Route path="/" element={<LandingPage />} />


            <Route path="/login" element={<Home />} />

            {/* Protected Routes */}
            <Route path={`/${nombre_empresa}/dashboard`} element={
                <ProtectedRoute isAuthenticated={isAuthenticated}>
                    <Dashboard />
                </ProtectedRoute>
            } />
            <Route path={`/${nombre_empresa}/mis-pedidos`} element={
                <ProtectedRoute isAuthenticated={isAuthenticated}>
                    <MisPedidos />
                </ProtectedRoute>
            } />
            <Route path={`/${nombre_empresa}/nuevo-pedido`} element={
                <ProtectedRoute isAuthenticated={isAuthenticated}>
                    <NuevoPedido />
                </ProtectedRoute>
            } />
            <Route path={`/${nombre_empresa}/carrito`} element={
                <ProtectedRoute isAuthenticated={isAuthenticated}>
                    <Carrito />
                </ProtectedRoute>
            } />
        </Routes>
    );
}

const mapStateToProps = state => ({
    isAuthenticated: state.auth.isAuthenticated,
    nombre_empresa: state.auth.empresa?.nombre.toLowerCase(),
});

export default connect(mapStateToProps, {
    refresh,
    check_authenticated,
    load_user
})(AnimatedRoutes);
