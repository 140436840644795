import { addToCart } from "redux/actions/Cart/cart"
import { connect } from "react-redux"
import { useEffect, useRef, useState } from "react"
import { get_image_product } from "redux/actions/products/products"
//import OrderCardHorizontal from "components/orders/OrderCardHorizontal"
import { XMarkIcon } from "@heroicons/react/24/solid"
//import { Link } from "react-router-dom"
import axios from 'axios'
import xmlParser from 'xml-js';
//import { jsPDF } from "jspdf";
//import { PDFDocument } from 'pdf-lib'
import JSZip from 'jszip';


function ProductCard({
    data,
    index,
    addToCart,
    //subtractToCart,
    carrito,
    image_product,
    lan,
    pool,
    get_image_product
}) {
    const productoEnCarrito = carrito.find(item => item.COD_ARTICULO === data.COD_ARTICULO);
    const [isVisible, setIsVisible] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const ref = useRef(null);
    const [cantidades, setCantidades] = useState(productoEnCarrito ? productoEnCarrito.cantidad : 0);

    useEffect(() => {
        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                    observer.unobserve(entry.target);
                }
            });
        });

        const currentRef = ref.current;

        if (currentRef) {
            observer.observe(currentRef);
        }

        return () => {
            if (currentRef) {
                observer.unobserve(currentRef);
            }
        };
    }, []);

    useEffect(() => {
        if (isVisible) {
            get_image_product(lan, pool, data.COD_ARTICULO);
        }
    }, [isVisible, data.COD_ARTICULO, get_image_product]);

    const handleAñadir = () => {
        if (cantidades === 0) {
            setCantidades(1); // Establece la cantidad en 1 al añadir al carrito por primera vez

            data = {
                ...data,
                image: image_product[data.COD_ARTICULO] || 'default_image_link'
            }

            addToCart(data, 1); // Añadir un producto al carrito
        }
    };

    async function getAdjunto(ABREV, CODIGO) {
        const soapBody = `<soapenv:Envelope xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:wss="http://www.adonix.com/WSS">
        <soapenv:Header/>
        <soapenv:Body>
          <wss:run soapenv:encodingStyle="http://schemas.xmlsoap.org/soap/encoding/">
            <callContext xsi:type="wss:CAdxCallContext">
                <codeLang xsi:type="xsd:string">SPA</codeLang>
                <poolAlias xsi:type="xsd:string">ADC_SEED</poolAlias>
                <poolId xsi:type="xsd:string">ADC_SEED</poolId>
                <requestConfig xsi:type="xsd:string">adxwss.trace.on=off&amp;adxwss.beautify=true&amp;adxwss.optreturn=JSON</requestConfig>  
             </callContext>    
            <publicName xsi:type="xsd:string">YGETPDF</publicName>
            <inputXml xsi:type="xsd:string">
            <![CDATA[<?xml version="1.0" encoding="UTF-8"?>
            <PARAM>
                <GRP ID="GRP1">        
                    <FLD NAME="ABREV">${ABREV}</FLD>
                    <FLD NAME="CODIGO">${CODIGO}</FLD>
                </GRP>
            </PARAM>
            ]]>
            </inputXml>
          </wss:run>
        </soapenv:Body>
        </soapenv:Envelope>`;

        const config = {
            headers: {
                'Content-Type': 'text/xml',
                'SOAPAction': '#POST'
            },
            auth: {
                username: process.env.REACT_APP_USER_SAGE,
                password: process.env.REACT_APP_PWD_SAGE
            }
        };

        try {
            const res = await axios.post(`${process.env.REACT_APP_SAGE_API_URL}`, soapBody, config);

            // Convertir XML a JSON
            const jsonPayload = xmlParser.xml2json(res.data, { compact: true, spaces: 4 });

            // Parsear el JSON para acceder a PS_TOKEN
            const result = JSON.parse(jsonPayload);
            const psToken = result['soapenv:Envelope']['soapenv:Body']['wss:runResponse']['runReturn']['resultXml']._cdata;

            res.data = JSON.parse(psToken);

            let arrayAdjuntos = res.data.GRP2;

            // Generar y descargar el PDF
            await generarPDF(arrayAdjuntos);

            return res.data;
        } catch (error) {
            console.error("Error en la solicitud SOAP:", error);
            throw error;
        }
    }

    async function generarPDF(arrayAdjuntos) {
        // Función auxiliar para decodificar base64 a Uint8Array
        const base64ToUint8Array = (base64String) => {
            // const cleanBase64 = base64String.replace(/[^A-Za-z0-9+/=]/g, '');
            const paddedBase64 = base64String.padEnd(base64String.length + (4 - base64String.length % 4) % 4, '=');
            const binaryString = atob(paddedBase64);
            const bytes = new Uint8Array(binaryString.length);
            for (let i = 0; i < binaryString.length; i++) {
                bytes[i] = binaryString.charCodeAt(i);
            }
            return bytes;
        };

        // Si solo hay un adjunto, descarga directamente el PDF
        if (arrayAdjuntos.length === 1) {
            const adjunto = arrayAdjuntos[0];
            try {
                // const base64String = adjunto.FICHERO_B64.split(',')[1];
                const bytes = base64ToUint8Array(adjunto.FICHERO_B64);

                // Crear un Blob y disparar la descarga
                const blob = new Blob([bytes], { type: 'application/pdf' });
                const downloadUrl = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = downloadUrl;
                link.setAttribute('download', `${adjunto.NOMBRE_FICHERO}.pdf`);
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
                window.URL.revokeObjectURL(downloadUrl);
            } catch (e) {
                console.error(`Error al procesar el PDF: ${adjunto.NOMBRE_FICHERO}`, e);
            }
        } else {
            // Si hay varios adjuntos, comprime en ZIP
            const zip = new JSZip();
            for (const adjunto of arrayAdjuntos) {
                try {
                    // const base64String = adjunto.FICHERO_B64.split(',')[1];
                    const bytes = base64ToUint8Array(adjunto.FICHERO_B64);

                    // Añadir al ZIP
                    zip.file(`${adjunto.NOMBRE_FICHERO}.pdf`, bytes, { binary: true });
                } catch (e) {
                    console.error(`Error al procesar el PDF: ${adjunto.NOMBRE_FICHERO}`, e);
                }
            }

            // Genera el Blob del archivo zip y dispara la descarga
            zip.generateAsync({ type: 'blob' }).then((content) => {
                const downloadUrl = window.URL.createObjectURL(content);
                const link = document.createElement('a');
                link.href = downloadUrl;
                link.setAttribute('download', 'adjuntos.zip');
                document.body.appendChild(link);
                link.click();
                link.parentNode.removeChild(link);
                window.URL.revokeObjectURL(downloadUrl);
            }).catch((e) => {
                console.error('Error al generar el archivo ZIP', e);
            });
        }
    }


    return (
        <div key={index} ref={ref} className="m-4  bg-white p-4 border border-pink-600 rounded-3xl shadow-md w-80 h-96 flex flex-col justify-center mx-auto">
            <div className="flex flex-col items-center mb-2">
                {image_product[data.COD_ARTICULO] ? (
                    <img src={`data:image/jpeg;base64,${image_product[data.COD_ARTICULO]}`} alt={data.DESCRIPCION} className="mb-4 rounded-3xl object-contain h-28" />
                ) : (
                    <img src="https://e7.pngegg.com/pngimages/829/733/png-clipart-logo-brand-product-trademark-font-not-found-logo-brand.png" alt="not found" className="w-fit h-auto mb-4 rounded-3xl bg-gray-200" />
                )}
                <h2 className="text-xl font-semibold text-black">{data.COD_ARTICULO}</h2>
                <p className="text-gray-700 text-sm mb-2 line-clamp-1">{data.DESCRIPCION}</p>
            </div>

            <div className="flex flex-row justify-center font-semibold space-x-4">
                <button className="text-pink-600 bg-white border border-pink-600 rounded-full py-1 px-3.5" onClick={() => setShowPopup(!showPopup)}>Detalles</button>
                {showPopup && (
                    <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
                        <div className="bg-white rounded-3xl px-8  pb-8  pt-1 max-w-md sm:max-w-xl border-pink-600 border">
                            <div className="flex justify-end mb-5">
                                <button className="flex items-center justify-center h-9 text-pink-500 hover:text-pink-600  " onClick={() => setShowPopup(!showPopup)}>
                                    <XMarkIcon className="h-5 w-5" />
                                </button>

                            </div>
                            <div className="flex flex-row mb-5">
                                <img src={`data:image/jpeg;base64,${image_product[data.COD_ARTICULO]}`} alt={data.DESCRIPCION} className="h-36 w-fit"></img>
                                <div className="flex flex-col pl-2">
                                    <h2 className="text-xl font-semibold ">{data.COD_ARTICULO}</h2>
                                    <h2 className="text-xl font-semibold mb-4">{data.DESCRIPCION}</h2>
                                    <button onClick={() => getAdjunto("ITM", data.COD_ARTICULO)} className="text-sm underline">
                                        Descargar PDF
                                    </button>
                                </div>
                            </div>
                            <div className="flex flex-row mb-8">
                                <div className="flex flex-col">
                                    <p className="text-pink-600 font-semibold">Unidad en stock:</p>
                                    <p className="text-pink-600 font-semibold">Peso:</p>
                                    <p className="text-pink-600 font-semibold">Volumen:</p>
                                </div>
                                <div className="flex flex-col pl-28">
                                    <p className="">{data.STOCK} {data.UNIDAD_MEDIDA}</p>
                                    <p className="">{data.PESO} {data.UNIDAD_PESO}</p>
                                    <p className="">{data.VOLUMEN} {data.UNIDAD_VOLUMEN}</p>
                                </div>
                            </div>
                            <div className="flex flex-row mb-1">
                                <div className="flex flex-col">
                                    <p className="text-pink-600 font-semibold">Precio:</p>
                                </div>
                                <div className="flex flex-col pl-48">
                                    <p className="">{data.PRECION} €</p>
                                </div>
                            </div>


                        </div>
                    </div>
                )}

                <button
                    className={`cursor-pointer py-1 px-3.5 duration-100 ${cantidades > 0 ? 'bg-pink-600 text-white cursor-not-allowed  rounded-full' :
                        'bg-pink-500 hover:bg-pink-600 text-white rounded-full'}`}
                    onClick={handleAñadir}
                    disabled={cantidades > 0}
                >
                    {cantidades > 0 ? 'Añadido' : 'Añadir'}
                </button>


            </div>
            <div className="w-full text-center mt-4">
                <h2 className="text-xl font-semibold">{data.PRECION} €</h2>
            </div>
        </div>
    );
}

// Define mapStateToProps if necessary or use a different approach to map state to props
const mapStateToProps = state => ({
    image_product: state.products.image_product,
    carrito: state.cart.cart,
    lan: state.auth.user?.idioma,
    pool: state.auth.user?.default_poolid,
});

export default connect(mapStateToProps, {
    addToCart,
    get_image_product,
})(ProductCard);
