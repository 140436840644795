import {
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    SET_AUTH_LOADING,
    REMOVE_AUTH_LOADING,
    LOGOUT,
    AUTHENTICATED_SUCCESS,
    AUTHENTICATED_FAIL,
    REFRESH_SUCCESS,
    REFRESH_FAIL,
    USER_LOADED_SUCCESS,
    USER_LOADED_FAIL
} from './types';
import axios from 'axios'
// import log from 'loglevel'

// log.setLevel('silent');

export const load_user = () => async dispatch => {
    if (localStorage.getItem('access')) {
        // console.log('Loading user with token:', localStorage.getItem('access'));

        const config = {
            headers: {
                'Authorization': `JWT ${localStorage.getItem('access')}`,
                'Accept': 'application/json'
            }
        };

        try {
            const res = await axios.get(`${process.env.REACT_APP_API_URL}/auth/users/me/`, config);

            if (res.status === 200) {
                // console.log('User loaded:', res.data);
                dispatch({
                    type: USER_LOADED_SUCCESS,
                    payload: res.data
                });
            } else {
                // console.log('Failed to load user');
                dispatch({
                    type: USER_LOADED_FAIL
                });
            }
        } catch (err) {
            // console.log('Error loading user:', err);
            dispatch({
                type: USER_LOADED_FAIL
            });
        }
    } else {
        // console.log('No access token found');
        dispatch({
            type: USER_LOADED_FAIL
        });
    }
};

export const login = (username, password) => async dispatch => {
    
    dispatch({
        type: SET_AUTH_LOADING,
    });
    
    const config = {
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        }
    };

    const body = JSON.stringify({
        username,
        password
    })
    
    try {
        
        const res = await axios.post(`${process.env.REACT_APP_API_URL}/auth/jwt/create/`, body, config)
        console.log('5')
        if (res.status === 200) {
            localStorage.setItem('access', res.data.access);
            localStorage.setItem('refresh', res.data.refresh);

            dispatch({
                type: LOGIN_SUCCESS,
                payload: res.data,
            });

            dispatch(load_user());

            dispatch({
                type: REMOVE_AUTH_LOADING,
            });
        } else {
            dispatch({
                type: LOGIN_FAIL,
            });

            dispatch({
                type: REMOVE_AUTH_LOADING,
            });
        }

    } catch (err) {
        dispatch({
            type: LOGIN_FAIL,
        });

        dispatch({
            type: REMOVE_AUTH_LOADING,
        });
    }
};

export const check_authenticated = () => async dispatch => {
    if (localStorage.getItem('access')) {
        // console.log('Checking authentication for token:', localStorage.getItem('access'));

        const config = {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        };

        const body = JSON.stringify({
            token: localStorage.getItem('access')
        });

        try {
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/auth/jwt/verify`, body, config);

            if (res.status === 200) {
                // console.log('Token is valid');
                dispatch({
                    type: AUTHENTICATED_SUCCESS
                });
            } else {
                // console.log('Token is invalid');
                dispatch({
                    type: AUTHENTICATED_FAIL
                });
            }
        } catch (err) {
            // console.log('Error verifying token:', err);
            dispatch({
                type: AUTHENTICATED_FAIL
            });
        }
    } else {
        // console.log('No access token found');
        dispatch({
            type: AUTHENTICATED_FAIL
        });
    }
};

export const refresh = () => async dispatch => {
    if (localStorage.getItem('refresh')) {
        // console.log('Refreshing token with refresh token:', localStorage.getItem('refresh'));

        const config = {
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        };

        const body = JSON.stringify({
            refresh: localStorage.getItem('refresh')
        });

        try {
            const res = await axios.post(`${process.env.REACT_APP_API_URL}/auth/jwt/refresh/`, body, config);

            if (res.status === 200) {
                // console.log('Token refreshed:', res.data);
                dispatch({
                    type: REFRESH_SUCCESS,
                    payload: res.data
                });
            } else {
                // console.log('Failed to refresh token');
                dispatch({
                    type: REFRESH_FAIL
                });
            }
        } catch (err) {
            // console.log('Error refreshing token:', err);
            dispatch({
                type: REFRESH_FAIL
            });
        }
    } else {
        // console.log('No refresh token found');
        dispatch({
            type: REFRESH_FAIL
        });
    }
};

export const logout = () => dispatch => {
    localStorage.removeItem('access');
    localStorage.removeItem('refresh');

    dispatch({
        type: LOGOUT
    });
};