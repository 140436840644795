import {
    GET_PRODUCT_LIST_SUCCESS,
    GET_PRODUCT_LIST_FAIL,
    GET_IMAGE_PRODUCT_SUCCESS,
    GET_IMAGE_PRODUCT_FAIL
} from '../actions/products/types';

const initialState = {
    product_list: null,
    product: null,
    image_product: {}
};

export default function products(state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case GET_PRODUCT_LIST_SUCCESS:
            return {
                ...state,
                product_list: payload.GRP2,
            }
        case GET_PRODUCT_LIST_FAIL:
            return {
                ...state,
                product_list: null,
            }
        case GET_IMAGE_PRODUCT_SUCCESS:

            const updatedImageProduct = { ...state.image_product };

            if (payload.GRP2.IMAGEN !== '') {
                updatedImageProduct[payload.GRP1.COD_ARTICULO] = payload.GRP2.IMAGEN;
            }

            return {
                ...state,
                image_product: updatedImageProduct,
            }
        case GET_IMAGE_PRODUCT_FAIL:
            return {
                ...state,
            }
        default:
            return state
    }
}