import Layout from "hocs/layouts/Layout";
import { connect } from "react-redux";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { useEffect, useState, useMemo } from "react";
import { get_product_list } from "redux/actions/products/products";
import ProductList from "components/newOrder/ProductList";

function NuevoPedido({
  lan,
  pool,
  sage_client,
  planta,
  moneda,
  get_product_list, 
  products,
  fondo_empresa,
}) {
  useEffect(() => {
    get_product_list(lan,pool,sage_client,planta,moneda);
  }, [get_product_list]);

  const [searchTerm, setSearchTerm] = useState("");
  const searchTermLower = searchTerm.toLowerCase();
  const [category, setCategory] = useState("");

  const handleCategoryChange = (e) => {
    setCategory(e.target.value);
  };

  const filteredProducts = useMemo(() => {
    return products
      ? products.filter(
          (product) =>
            (product.COD_ARTICULO.toLowerCase().includes(searchTermLower) ||
              product.DESCRIPCION.toLowerCase().includes(searchTermLower)) &&
            (!category || product.CATEGORIA === category)
        )
      : [];
  }, [products, searchTermLower, category]);

  return (
    <Layout>
      <div 
        className="flex-grow overflow-y-auto bg-cover hide-scrollbar"
        style={{ backgroundImage: `url(${fondo_empresa})`, backgroundSize: 'cover', backgroundPosition: 'center' }}
      >
        <div className="flex items-center md:grid-cols-2 gap-4 pt-20 sm:pt-16">
          <h1 className="text-pink-500 font-semibold mb-4 text-left text-4xl w-1/4 ml-14 md:mt-8 xs:mt-12">
            Nuevo Pedido
          </h1>
          <div className="flex justify-end w-3/4 pr-14">
            <form className="flex justify-start items-center space-x-2 mr-2 md:mt-8 xs:mt-12">
              <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                <i className="bx bx-search-alt text-xl text-gray-800"></i>
              </div>
              <MagnifyingGlassIcon className="absolute h-auto w-8 pt-0 pl-4 text-gray-600" />
              <input
                id="search"
                name="search"
                type="search"
                placeholder="Codigo / Desc / Título"
                className="py-1 pl-8 h-10 block w-full rounded-fullborder border-pink-600 rounded-full text-gray-900 focus:ring-gray-600 focus:outline-none placeholder-gray-700 bg-pink-100"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
              <div className="flex justify-start md:col-span-2 mr-6">
                <select
                  className="border border-pink-600 rounded-full text-gray-700 h-10 pl-5 pr-10 bg-pink-100 hover:border-pink-400 focus:outline-none appearance-none"
                  value={category}
                  onChange={handleCategoryChange}
                >
                  <option value="">Categoria</option>
                  <option value="Bañera">Bañera</option>
                  <option value="Ducha">Ducha</option>
                </select>
              </div>
            </form>
          </div>
        </div>

        <ProductList products={filteredProducts && filteredProducts} />
      </div>
    </Layout>
  );
}

const mapStateToProps = (state) => ({
  products: state.products.product_list,
  fondo_empresa: state.auth.empresa.imagen_background,
  sage_client: state.auth.user?.cliente_sage,
  lan: state.auth.user?.idioma,
  pool: state.auth.user?.default_poolid,
  planta:state.auth.user?.default_planta,
  moneda:state.auth.user?.codigo_moneda,
});

export default connect(mapStateToProps, {
  get_product_list,
})(NuevoPedido);
