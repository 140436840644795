import {
    GET_CONTACT_ADDRESS_SUCCESS,
    GET_CONTACT_ADDRESS_FAIL
} from '../actions/contact/types';

const initialState = {
    direcciones: null,
};

export default function contact(state = initialState, action) {
    const { type, payload } = action;

    switch(type) {
        case GET_CONTACT_ADDRESS_SUCCESS:
            return {
                ...state,
                direcciones: payload.GRP2,
            }
        case GET_CONTACT_ADDRESS_FAIL:
            return {
                ...state,
                direcciones: null,
            }
        default:
            return state
    }
}