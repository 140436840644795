import {
    LOGIN_SUCCESS,
    LOGIN_FAIL,
    SET_AUTH_LOADING,
    REMOVE_AUTH_LOADING,
    LOGOUT,
    AUTHENTICATED_SUCCESS,
    AUTHENTICATED_FAIL,
    REFRESH_SUCCESS,
    REFRESH_FAIL,
    USER_LOADED_SUCCESS,
    USER_LOADED_FAIL,
} from  '../actions/auth/types';

const initialState = {
    access: localStorage.getItem('access'),
    refresh: localStorage.getItem('refresh'),
    isAuthenticated: null,
    user: null,
    empresa: null,
    user_loading: false,
    auth_loading: false,
}

export default function auth(state = initialState, action){
    const { type, payload } = action;

    // switch(type) {
    //     case USER_LOADED_SUCCESS:
    //         return {
    //             ...state,
    //             user: payload,
    //             user_loading: false
    //         }
    //     case USER_LOADED_FAIL:
    //         return {
    //             ...state,
    //             user: null,
    //             user_loading: false
    //         }
    //     case SET_AUTH_LOADING:
    //         return {
    //             ...state,
    //             loading: true
    //         }
    //     case REMOVE_AUTH_LOADING:
    //         return {
    //             ...state,
    //             loading: false
    //         }
    //     case AUTHENTICATED_SUCCESS:
    //         return {
    //             ...state,
    //             isAuthenticated: true
    //         }
    //     case AUTHENTICATED_FAIL:
    //         localStorage.removeItem('access');
    //         localStorage.removeItem('refresh');
    //         return {
    //             ...state,
    //             isAuthenticated: false,
    //             access: null,
    //             refresh: null
    //         }
    //     case LOGIN_SUCCESS:
    //         localStorage.setItem('access', payload.access);
    //         localStorage.setItem('refresh', payload.refresh);
    //         return {
    //             ...state,
    //             isAuthenticated: true,
    //             access: localStorage.getItem('access'),
    //             refresh: localStorage.getItem('refresh')
    //         }
    //     case REFRESH_SUCCESS:
    //         localStorage.setItem('access', payload.access);
    //         return {
    //             ...state,
    //             access: localStorage.getItem('access')
    //         }
    //     case LOGIN_FAIL:
    //     case REFRESH_FAIL:
    //     case LOGOUT:
    //         localStorage.removeItem('access');
    //         localStorage.removeItem('refresh');
    //         return {
    //             ...state,
    //             access: null,
    //             refresh: null,
    //             isAuthenticated: false,
    //             user: null
    //         }
    //     default:
    //         return state
    // }
    switch (type) {
        case SET_AUTH_LOADING:
            return {
                ...state,
                auth_loading: true
            };
        case REMOVE_AUTH_LOADING:
            return {
                ...state,
                auth_loading: false
            };
        case LOGIN_SUCCESS:
            localStorage.setItem('access', payload.access);
            localStorage.setItem('refresh', payload.refresh);
            return {
                ...state,
                isAuthenticated: true,
                access: payload.access,
                refresh: payload.refresh,
                auth_loading: false
            };
        case USER_LOADED_SUCCESS:
            return {
                ...state,
                user: payload,
                empresa: payload.empresa,
                user_loading: false
            };
        case USER_LOADED_FAIL:
            return {
                ...state,
                user: null,
                empresa: null,
                user_loading: false
            };
        case AUTHENTICATED_SUCCESS:
            return {
                ...state,
                isAuthenticated: true
            };
        case AUTHENTICATED_FAIL:
            return {
                ...state,
                isAuthenticated: false,
                user: null
            };
        case REFRESH_SUCCESS:
            localStorage.setItem('access', payload.access);
            return {
                ...state,
                access: payload.access
            };
        case REFRESH_FAIL:
            return {
                ...state,
                isAuthenticated: false,
                user: null,
                access: null,
                refresh: null
            };
        case LOGIN_FAIL:
        case LOGOUT:
            localStorage.removeItem('access');
            localStorage.removeItem('refresh');
            return {
                ...state,
                access: null,
                refresh: null,
                isAuthenticated: false,
                user: null,
                auth_loading: false
            };
        default:
            return state;
    }
}