import axios from 'axios'
import xmlParser from 'xml-js';
import {
    GET_PRODUCT_LIST_SUCCESS,
    GET_PRODUCT_LIST_FAIL,
    //GET_PRODUCT_DETAIL_SUCCESS,
    //GET_PRODUCT_DETAIL_FAIL,
    GET_IMAGE_PRODUCT_SUCCESS,
    GET_IMAGE_PRODUCT_FAIL
} from "./types"

export const get_product_list = (lan,pool,sage_client,planta,moneda) => async dispatch => {
    const config = {
        headers: {
            'Content-Type': 'text/xml',
            'SOAPAction': '#POST'
        },
        auth: {
            username: `${process.env.REACT_APP_USER_SAGE}`,
            password: `${process.env.REACT_APP_PWD_SAGE}`
        }
    };

    const body = `<soapenv:Envelope xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:wss="http://www.adonix.com/WSS">
                    <soapenv:Header/>
                    <soapenv:Body>
                        <wss:run soapenv:encodingStyle="http://schemas.xmlsoap.org/soap/encoding/">
                            <callContext xsi:type="wss:CAdxCallContext">
                                <codeLang xsi:type="xsd:string">${lan}</codeLang>
                                <poolAlias xsi:type="xsd:string">${pool}</poolAlias>
                                <poolId xsi:type="xsd:string">${pool}</poolId>
                                <requestConfig xsi:type="xsd:string">adxwss.trace.on=off&amp;adxwss.beautify=true&amp;adxwss.optreturn=JSON</requestConfig>  
                            </callContext>    
                            <publicName xsi:type="xsd:string">GITMCLIPRI</publicName>
                            <inputXml xsi:type="xsd:string">
                            <![CDATA[<?xml version="1.0" encoding="UTF-8"?>
                                <PARAM>
                                    <GRP ID="GRP1">        
                                        <FLD NAME="CLIENTE">${sage_client}</FLD>
                                        <FLD NAME="PLANTA">${planta}</FLD>
                                        <FLD NAME="MONEDA">${moneda}</FLD>
                                    </GRP>
                                </PARAM>
                                ]]>
                            </inputXml>
                        </wss:run>
                    </soapenv:Body>
                </soapenv:Envelope>`

    try {

        const res = await axios.post(`${process.env.REACT_APP_SAGE_API_URL}`, body, config);

        // Convertir XML a JSON
        const jsonPayload = xmlParser.xml2json(res.data, { compact: true, spaces: 4 });

        // Parsear el JSON para acceder a PS_TOKEN
        const result = JSON.parse(jsonPayload);
        const psToken = result['soapenv:Envelope']['soapenv:Body']['wss:runResponse']['runReturn']['resultXml']._cdata;

        res.data = JSON.parse(psToken);

        if (res.status === 200) {
            dispatch({
                type: GET_PRODUCT_LIST_SUCCESS,
                payload: res.data
            });
        } else {
            dispatch({
                type: GET_PRODUCT_LIST_FAIL
            });
        }

    } catch (err) {
        dispatch({
            type: GET_PRODUCT_LIST_FAIL
        });
    }
}

export const get_image_product = (lan,pool,articulo) => async (dispatch) => {
    const config = {
        headers: {
            'Content-Type': 'text/xml',
            'SOAPAction': '#POST'
        },
        auth: {
            username: `${process.env.REACT_APP_USER_SAGE}`,
            password: `${process.env.REACT_APP_PWD_SAGE}`
        }
    };

    const body = `<soapenv:Envelope xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:wss="http://www.adonix.com/WSS">
                    <soapenv:Header/>
                    <soapenv:Body>
                        <wss:run soapenv:encodingStyle="http://schemas.xmlsoap.org/soap/encoding/">
                            <callContext xsi:type="wss:CAdxCallContext">
                                <codeLang xsi:type="xsd:string">${lan}</codeLang>
                                <poolAlias xsi:type="xsd:string">${pool}</poolAlias>
                                <poolId xsi:type="xsd:string">${pool}</poolId>
                                <requestConfig xsi:type="xsd:string">adxwss.trace.on=off&amp;adxwss.beautify=true&amp;adxwss.optreturn=JSON</requestConfig>  
                            </callContext>    
                            <publicName xsi:type="xsd:string">GETITMIMG</publicName>
                            <inputXml xsi:type="xsd:string">
                            <![CDATA[<?xml version="1.0" encoding="UTF-8"?>
                                <PARAM>
                                    <GRP ID="GRP1">        
                                        <FLD NAME="COD_ARTICULO">${articulo}</FLD>
                                    </GRP>
                                </PARAM>
                                ]]>
                            </inputXml>
                        </wss:run>
                    </soapenv:Body>
                </soapenv:Envelope>`

    try {

        const res = await axios.post(`${process.env.REACT_APP_SAGE_API_URL}`, body, config);

        // Convertir XML a JSON
        const jsonPayload = xmlParser.xml2json(res.data, { compact: true, spaces: 4 });

        // Parsear el JSON para acceder a PS_TOKEN
        const result = JSON.parse(jsonPayload);
        const psToken = result['soapenv:Envelope']['soapenv:Body']['wss:runResponse']['runReturn']['resultXml']._cdata;

        res.data = JSON.parse(psToken);

        if (res.status === 200) {
            dispatch({
                type: GET_IMAGE_PRODUCT_SUCCESS,
                payload: res.data
            });
        } else {
            dispatch({
                type: GET_IMAGE_PRODUCT_FAIL
            });
        }

    } catch (err) {
        dispatch({
            type: GET_IMAGE_PRODUCT_FAIL
        });
    }
}

// export const get_product_details = (articulo) => async (dispatch) => {
//     const config = {
//         headers: {
//             'Content-Type': 'text/xml',
//             'SOAPAction': '#POST'
//         },
//         auth: {
//             username: `${process.env.REACT_APP_USER_SAGE}`,
//             password: `${process.env.REACT_APP_PWD_SAGE}`
//         }
//     };

//     const body = `<soapenv:Envelope xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" xmlns:xsd="http://www.w3.org/2001/XMLSchema" xmlns:soapenv="http://schemas.xmlsoap.org/soap/envelope/" xmlns:wss="http://www.adonix.com/WSS">
//     <soapenv:Header/>
//     <soapenv:Body>
//         <wss:run soapenv:encodingStyle="http://schemas.xmlsoap.org/soap/encoding/">
//             <callContext xsi:type="wss:CAdxCallContext">
//                 <codeLang xsi:type="xsd:string">SPA</codeLang>
//                 <poolAlias xsi:type="xsd:string">ADC_SEED</poolAlias>
//                 <poolId xsi:type="xsd:string">ADC_SEED</poolId>
//                 <requestConfig xsi:type="xsd:string">adxwss.trace.on=off&amp;adxwss.beautify=true&amp;adxwss.optreturn=JSON</requestConfig>  
//             </callContext>    
//             <publicName xsi:type="xsd:string">GETITMIMG</publicName>
//             <inputXml xsi:type="xsd:string">
//             <![CDATA[<?xml version="1.0" encoding="UTF-8"?>
//                 <PARAM>
//                     <GRP ID="GRP1">        
//                         <FLD NAME="COD_ARTICULO">${articulo}</FLD>
//                     </GRP>
//                 </PARAM>
//                 ]]>
//             </inputXml>
//         </wss:run>
//     </soapenv:Body>
//     </soapenv:Envelope>`

//     try {

//         const res = await axios.post(`${process.env.REACT_APP_SAGE_API_URL}`, body, config);

//         // Convertir XML a JSON
//         const jsonPayload = xmlParser.xml2json(res.data, { compact: true, spaces: 4 });

//         // Parsear el JSON para acceder a PS_TOKEN
//         const result = JSON.parse(jsonPayload);
//         const psToken = result['soapenv:Envelope']['soapenv:Body']['wss:runResponse']['runReturn']['resultXml']._cdata;

//         res.data = JSON.parse(psToken);

//         if (res.status === 200) {
//             dispatch({
//                 type: GET_PRODUCT_DETAIL_SUCCESS,
//                 payload: res.data
//             });
//         } else {
//             dispatch({
//                 type: GET_PRODUCT_DETAIL_FAIL
//             });
//         }

//     } catch (err) {
//         dispatch({
//             type: GET_PRODUCT_DETAIL_FAIL
//         });
//     }
// }