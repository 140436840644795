import React, { useState, useRef, useEffect } from 'react';
import { motion } from 'framer-motion';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';
import i3logo from "assets/img/LogoInfinity.png";
import sagePartner from "assets/img/SagePartner.png";
import logoNoTextNeg from "assets/img/logo-sin-texto-neg.png";
import logoNunsysNeg from "assets/img/Infinity3_logo-neg.png";
import AppMobileImage from 'assets/img/movil.png';
import webImage from 'assets/img/fondo_web.png';
import infinity3 from 'assets/video/Infinity3_landing.mp4';

const sections = ['principal', 'app-mobile', 'web-app', 'contacto'];

const TopNav = ({ onNavigate }) => {
    const [isOpen, setIsOpen] = useState(false);

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    };

    const handleNavigate = (section) => {
        onNavigate(section);
        setIsOpen(false); // Cerrar el menú al seleccionar una opción
    };

    return (
        <div className="fixed top-0 left-0 w-full py-4 px-4 md:px-[4vw] bg-white z-[1000] border-b-2 shadow-lg">
            <nav className="flex items-center justify-between w-full">
                <button onClick={() => handleNavigate('principal')} className="flex-shrink-0 cursor-pointer">
                    <img src={i3logo} alt="Logo empresa" className="h-[4vh] md:h-[6vh]" />
                </button>
                <div className="md:hidden">
                    <button onClick={toggleMenu} className="text-black">
                        <FontAwesomeIcon icon={isOpen ? faTimes : faBars} size="2x" />
                    </button>
                </div>
                <div className={`flex-col md:flex md:flex-row items-center md:space-x-[5vw] ${isOpen ? 'flex' : 'hidden'} md:flex`}>
                    <button onClick={() => handleNavigate('app-mobile')} className="font-raleway text-[1.5rem] md:text-[2rem] text-neutral-lt-nav hover:text-primary-lt cursor-pointer">
                        AppMobile
                    </button>
                    <button onClick={() => handleNavigate('web-app')} className="font-raleway text-[1.5rem] md:text-[2rem] text-neutral-lt-nav hover:text-primary-lt cursor-pointer">
                        WebApp
                    </button>
                    <button onClick={() => handleNavigate('contacto')} className="font-raleway text-[1.5rem] md:text-[2rem] text-neutral-lt-nav hover:text-primary-lt cursor-pointer">
                        Contacto
                    </button>
                    <a href="/login" className="bg-accent-1 p-[1vw] rounded-3xl border-2 border-black text-white text-center text-[1.5rem]">
                        Acceso usuarios
                    </a>
                </div>
            </nav>
        </div>
    );
};

const SectionHeading = (props) => {
    return (
        <div className="flex flex-col items-center justify-center">
            <h1 className="text-[4.5vw] sm:text-[2vw] font-bold text-primary-lt mb-[4vh] mt-[5vh] text-center font-sans tracking-tight leading-none">{props.tittle}</h1>
            <h2 className="text-[3.5vw] sm:text-[1.5vw] font-semibold text-black text-center font-sans tracking-tight leading-none">{props.subtittle}</h2>
            <img className="w-[30vw] sm:w-[10vw]" src={sagePartner} alt="Sage Partner Logo" />
        </div>
    );
};

const VideoCard = ({ isActive }) => {
    const videoRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [showPauseButton, setShowPauseButton] = useState(false);

    useEffect(() => {
        if (videoRef.current && !isActive) {
            videoRef.current.pause();
            setIsPlaying(false);
        }
    }, [isActive]);

    useEffect(() => {
        if (videoRef.current) {
            videoRef.current.currentTime = 1;
            videoRef.current.pause();
        }
    }, []);

    const handlePlay = () => {
        if (videoRef.current) {
            videoRef.current.play();
            setIsPlaying(true);
            setShowPauseButton(false);
        }
    };

    const handlePause = () => {
        if (videoRef.current) {
            videoRef.current.pause();
            setIsPlaying(false);
        }
    };

    const handleMouseEnter = () => {
        if (isPlaying) {
            setShowPauseButton(true);
        }
    };

    const handleMouseLeave = () => {
        setShowPauseButton(false);
    };

    return (
        <div className="relative flex justify-center items-center w-full max-w-[80vw] mx-auto">
            <div className="relative w-full sm:w-5/12 h-full border-[2vw] md:border-[0.5vw] p-1 border-black rounded-3xl">
                <div className="absolute top-0 mt-1 left-1/2 transform -translate-x-1/2 sm:w-[5vw] sm:h-[2vh] w-[4vw] h-[2vh] bg-black rounded-full flex items-center justify-center z-50">
                    <div className="w-[2vh] h-[2vh] sm:w-[2vh] sm:h-[2vh] bg-gray-800 border-[0.3vh] border-gray-400 rounded-full"></div>
                </div>
                <div
                    className="relative w-full h-full"
                    onMouseEnter={handleMouseEnter}
                    onMouseLeave={handleMouseLeave}
                >
                    <video
                        ref={videoRef}
                        width="100%"
                        height="100%"
                        className="rounded-lg"
                    >
                        <source src={infinity3} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                    {!isPlaying ? (
                        <button
                            onClick={handlePlay}
                            className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 text-white text-[4vh]"
                        >
                            ▶
                        </button>
                    ) : (
                        showPauseButton && (
                            <button
                                onClick={handlePause}
                                className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 text-white text-[4vh]"
                            >
                                ❚❚
                            </button>
                        )
                    )}
                </div>
            </div>
        </div>
    );
};

const Principal = ({ isActive }) => {
    return (
        <div className="flex flex-col items-center justify-center w-full mt-4 sm:mt-0" name='principal'>
            <div className="w-full min-h-[80vh] sm:min-h-[90vh] bg-landing1 bg-center bg-cover pt-[10vh]">
                <SectionHeading tittle="OPTIMIZA TUS RECURSOS CON INFINITY 3" subtittle="Conectando en tiempo real con SAGE X3" />
                <div className="flex justify-center items-center w-full px-[2vw]">
                    <VideoCard isActive={isActive} />
                </div>
            </div>
        </div>
    );
};

const AppMobile = () => {
    return (
        <div className="container mx-auto mt-4 sm:mt-0">
            <div className="flex flex-col md:flex-row justify-start w-full bg-mockup1 bg-no-repeat bg-contain md:bg-auto bg-top md:bg-left min-h-[70vh] sm:min-h-[90vh] pt-[2vh] mt-[11vh] sm:pt-0 sm:mt-0 md:ml-[3vw] sm:space-x-[10vw]" name="app-mobile">
                {/* Imagen en la parte superior izquierda en móvil */}
                <div className="flex items-center justify-center w-full md:w-[50vw] p-[2vw] md:p-0 md:-ml-[20vh]">
                    <img src={AppMobileImage} alt="Sample" className="w-[80%] md:w-[70vh] h-auto max-w-full max-h-screen sm:pt-[6vh]" />
                </div>

                {/* Texto alineado a la derecha en móvil y distribuido en la pantalla completa */}
                <div className="flex flex-col items-center md:items-start justify-start w-full md:w-[40vw] px-[4vw] md:px-0 font-sans pt-[3vh] sm:pt-[6vh] space-y-[1vh] sm:space-y-[5vh] sm:text-right md:pt-[30vh] md:pr-[5vw] md:pb-0">
                    <h1 className="text-[4vw] md:text-[2vw] font-bold mb-4 text-center md:text-left text-primary-lt">Multiplataforma, fácil e intuitiva</h1>
                    <div className="text-justify text-black space-y-[1.5vh] sm:space-y-[4vh] w-full md:w-auto">
                        <p className="text-[3vw] md:text-[1vw]">Controla tus tareas <b>logísticas, comerciales, productivas</b> y de <b>proyectos</b> desde cualquier dispositivo.</p>

                        <p className="text-[3vw] md:text-[1vw] ml-0 md:ml-[3vw]">Manten tus <b>Datos seguros</b> y conectados en <b>tiempo real</b> con SAGE X3.</p>

                        <p className="text-[3vw] md:text-[1vw]">Esta herramienta <b>intuitiva y eficiente</b> asegura que tus <b>operaciones</b> se mantengan <b>actualizadas y sincronizadas</b>.</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

const WebApp = () => {
    return (
        <div className="container mx-auto">
            <div className="flex flex-col md:flex-row justify-start w-full bg-mockup2 bg-no-repeat bg-contain md:bg-auto bg-top md:bg-left min-h-[70vh] sm:min-h-[90vh] pt-[2vh] mt-[11vh] sm:mt-0 sm:pt-[10vh]" name="web-app">
                <div className="flex items-center justify-center w-full md:w-[50vw] pt-[10vh] sm:pt-0 md:pr-[15vw]">
                    <img src={webImage} alt="Sample" className="w-[85%] md:w-[30vw] h-auto border-4 border-black max-w-full max-h-screen" />
                </div>
                <div className="flex flex-col items-center md:items-start justify-center w-full md:w-[40vw] p-4 md:px-0 font-sans pt-[14vh] sm:pt-[6vh] space-y-[1vh] sm:space-y-[5vh] md:pl-[1vw]">
                    <h1 className="text-[4vw] md:text-[2vw] font-bold mb-4 text-center md:text-left text-primary-lt">Simplifica la gestión de tus clientes y proveedores</h1>
                    <div className="text-justify text-black space-y-[1.5vh] sm:space-y-[4vh]">
                        <p className="md:text-[1vw] text-[3vw]">Con infinity 3 WebApp, tus socios comerciales podrán <b>realizar</b> pedidos de manera fácil y rápida, <b>revisar</b> el estado de sus pedidos en tiempo real y <b>descargar</b> facturas y albaranes en <b>cualquier momento!</b></p>

                        <p className="md:text-[1vw] text-[3vw] md:ml-[3vw]">Esta herramienta <b>intuitiva</b> y <b>eficiente</b> asegura que todas las transacciones y comunicaciones se mantengan <b>actualizadas</b> y <b>sincronizadas</b> con SAGE X3, mejorando significativamente la <b>eficiencia</b> operativa y la <b>satisfacción</b> del cliente.</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

const Contacto = () => {
    const handleSubmit = (e) => {
        e.preventDefault();
        window.location.href = 'mailto:info@infinity3.es';
    };

    return (
        <div name="contacto" className="flex flex-col items-center justify-start bg-landing2 bg-cover bg-center min-h-[81vh] sm:min-h-[90vh] px-[5vw] sm:px-[2vw] pt-[10vh] sm:pt-[16vh]">
            <h1 className="text-[7vw] sm:text-[1.75vw] font-bold text-primary-lt text-center font-sans tracking-tight leading-none">Contactanos</h1>
            <div className="mt-[4vh] w-full max-w-[85vw] sm:max-w-[40vw] lg:max-w-[30vw]">
                <div className="relative p-[5vw] sm:p-[2vw] bg-white border-[1vh] border-black shadow-lg rounded-tr-[10vw] sm:rounded-tr-[5vw]" style={{ boxShadow: '0.5vw -0.5vw 0 rgb(255, 31, 165)' }}>
                    <form id="contactForm" className="space-y-[2vw] sm:space-y-[1vh]" onSubmit={handleSubmit}>
                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-[2vw] sm:gap-[1vh]">
                            <div>
                                <label htmlFor="name" className="block text-black text-[4.5vw] sm:text-[1.25vw]">Nombre</label>
                                <input type="text" id="name" name="name" className="w-full p-[3vw] sm:p-[0.5vw] border-2 text-black text-[4.5vw] sm:text-[1.25vw] border-black rounded-md" required />
                            </div>
                            <div>
                                <label htmlFor="company" className="block text-black text-[4.5vw] sm:text-[1.25vw]">Empresa</label>
                                <input type="text" id="company" name="company" className="w-full p-[3vw] sm:p-[0.5vw] border-2 text-black text-[4.5vw] sm:text-[1.25vw] border-black rounded-md" required />
                            </div>
                        </div>
                        <div className="grid grid-cols-1 sm:grid-cols-2 gap-[2vw] sm:gap-[1.5vh]">
                            <div>
                                <label htmlFor="email" className="block text-black text-[4.5vw] sm:text-[1.25vw]">Email</label>
                                <input type="email" id="email" name="email" className="w-full p-[3vw] sm:p-[0.5vw] border-2 text-black text-[4.5vw] sm:text-[1.25vw] border-black rounded-md" required />
                            </div>
                            <div>
                                <label htmlFor="phone" className="block text-black text-[4.5vw] sm:text-[1.25vw]">Teléfono</label>
                                <input type="tel" id="phone" name="phone" className="w-full p-[3vw] sm:p-[0.5vw] border-2 text-black text-[4.5vw] sm:text-[1.25vw] border-black rounded-md" required />
                            </div>
                        </div>
                        <div>
                            <label htmlFor="message" className="block text-black text-[4.5vw] sm:text-[1.25vw]">Déjanos un mensaje</label>
                            <textarea id="message" name="message" className="w-full p-[3vw] sm:p-[0.75vw] border-2 text-black text-[4.5vw] sm:text-[1.25vw] border-black rounded-md" rows="1" required></textarea>
                        </div>
                        <div className="flex justify-center">
                            <button type="submit" className="px-[1.5vw] py-[0.5vw] text-white text-[5vw] sm:text-[1.5vw] rounded-full" style={{ backgroundColor: 'rgb(255, 31, 165)', border: '2px solid black' }}>Enviar</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>


    );
};

const Footer = () => {
    return (
        <div className="bottom-0 w-full text-white">
            <div className="flex flex-col md:flex-row justify-between items-center sm:items-end h-auto md:h-[10vh] space-y-[3vh] md:space-y-0 px-[4vw] md:px-[15vw] bg-[#ff0c9e] md:bg-transparent md:bg-footer_landing bg-cover p-[1vh]">
                {/* Parte Izquierda */}
                <div className="flex flex-row items-center text-center md:text-left space-y-[2vh] md:space-y-0">
                    <p className="text-[2.5vw] md:text-[2vh]">Infinity3 es una creación del grupo</p>
                    <img
                        src={logoNunsysNeg}
                        alt="logo Infinity"
                        className="w-[10vw] sm:w-[3vw] h-auto md:ml-[2vw]"
                    />
                </div>

                {/* Parte Derecha en formato centrado para móviles y a la derecha en pantallas grandes */}
                <div className="flex flex-row justify-center items-center text-center md:text-right space-x-[4vw] md:space-x-[2vw]">
                    <a href="#" className="text-[2.5vw] md:text-[1.5vh] hover:underline">Política de privacidad</a>
                    <a href="#" className="text-[2.5vw] md:text-[1.5vh] hover:underline">Cookies</a>
                    <a href="#" className="text-[2.5vw] md:text-[1.5vh] hover:underline">Condiciones de uso</a>
                </div>

                {/* Derechos Reservados y Logo */}
                <div className="flex flex-col items-center text-center md:flex-row md:justify-end space-y-[1.5vh] md:space-y-0 md:space-x-[1vh]">
                    <p className="text-[2.5vw] md:text-[1.5vh]">Todos los derechos reservados.</p>
                    <img
                        src={logoNoTextNeg}
                        alt="logo Infinity"
                        className="w-[10vw] sm:w-[3vw] w-[3vw] h-auto"
                    />
                </div>
            </div>
        </div>
    );
};

const LandingPage = () => {
    const [activeSection, setActiveSection] = useState('principal');

    const handleNavigation = (sectionId) => {
        if (sections.includes(sectionId)) {
            setActiveSection(sectionId);
        }
    };

    return (
        <div className='font-raleway text-[3vw] text-neutral-dk' style={{ overflowX: 'hidden' }}>
            <TopNav onNavigate={handleNavigation} />

            <motion.div
                key="principal"
                initial={{ opacity: 0, x: -100 }}
                animate={{ opacity: activeSection === 'principal' ? 1 : 0, x: activeSection === 'principal' ? 0 : 100 }}
                transition={{ duration: 0.5 }}
                style={{ display: activeSection === 'principal' ? 'block' : 'none' }}
            >
                <Principal isActive={activeSection === 'principal'} />
            </motion.div>

            <motion.div
                key="app-mobile"
                initial={{ opacity: 0, x: -100 }}
                animate={{ opacity: activeSection === 'app-mobile' ? 1 : 0, x: activeSection === 'app-mobile' ? 0 : 100 }}
                transition={{ duration: 0.5 }}
                style={{ display: activeSection === 'app-mobile' ? 'block' : 'none' }}
            >
                <AppMobile />
            </motion.div>

            <motion.div
                key="web-app"
                initial={{ opacity: 0, x: -100 }}
                animate={{ opacity: activeSection === 'web-app' ? 1 : 0, x: activeSection === 'web-app' ? 0 : 100 }}
                transition={{ duration: 0.5 }}
                style={{ display: activeSection === 'web-app' ? 'block' : 'none' }}
            >
                <WebApp />
            </motion.div>

            <motion.div
                key="contacto"
                initial={{ opacity: 0, x: -100 }}
                animate={{ opacity: activeSection === 'contacto' ? 1 : 0, x: activeSection === 'contacto' ? 0 : 100 }}
                transition={{ duration: 0.5 }}
                style={{ display: activeSection === 'contacto' ? 'block' : 'none' }}
            >
                <Contacto />
            </motion.div>

            <Footer />
        </div>
    );
};

export default LandingPage;
