import Layout from "hocs/layouts/Layout";
import { connect } from "react-redux";
import React, { useContext, useEffect, useMemo } from "react";
import DOMPurify from 'dompurify';
import { HeightContext } from "components/utils/HeightContext";
import { get_product_list } from "redux/actions/products/products";
import { get_order_list } from "redux/actions/orders/orders";
import { get_contact_address } from "redux/actions/contact/contact";

function Dashboard({ empresa, get_order_list, lan, pool, sage_client, planta, moneda, get_product_list, get_contact_address }) {
  useEffect(() => {
    get_order_list(lan, pool, sage_client);
    get_product_list(lan, pool, sage_client, planta, moneda);
    get_contact_address(lan, pool, sage_client);
  }, [get_order_list, sage_client, get_product_list, get_contact_address]);

  const { navHeight, footerHeight, recalculateHeights } = useContext(HeightContext);

  useEffect(() => {
    recalculateHeights();
  }, [recalculateHeights]);

  const calculateDistance = () => {
    const windowHeight = window.innerHeight;
    return windowHeight - navHeight - footerHeight;
  };

  const distance = useMemo(() => calculateDistance(), [navHeight, footerHeight]);

  return (
    <Layout>
      <div
        className="flex min-h-screen items-center justify-center bg-cover"
        style={{
          backgroundImage: `url(${empresa?.imagen_background})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <div
          className="flex flex-row w-full mt-6"
          style={{ height: `${distance}px` }}
        >
          <div className="bg-white bg-opacity-85 h-full w-96 border-r-2 border-r-pink-600">
            <img
              src={empresa?.imagen_corporativa}
              alt={empresa?.nombre}
              className="h-60 w-60 ml-60 mt-20 p-0.5 object-fill border-2 rounded-xl border-pink-600 bg-white"
            />
            <p className="font-raleway p-6 text-justify" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(empresa?.descripcion) }} />
          </div>
        </div>
      </div>
    </Layout>
  );
}

const mapStateToProps = (state) => ({
  empresa: state.auth.empresa,
  sage_client: state.auth.user?.cliente_sage,
  lan: state.auth.user?.idioma,
  pool: state.auth.user?.default_poolid,
  planta: state.auth.user?.default_planta,
  moneda: state.auth.user?.codigo_moneda,
});

export default connect(mapStateToProps, {
  get_order_list,
  get_product_list,
  get_contact_address,
})(Dashboard);
