import { useEffect } from 'react'
import { connect } from 'react-redux';
import { get_order } from 'redux/actions/orders/orders';

function OrderCardHorizontal({
    num_pedido,
    pedido,
    index,
    lan,
    pool,
    get_order
}) {

    useEffect(() => {
        get_order(lan, pool, num_pedido)
    }, [get_order, num_pedido])

    return (
        <div key={index} className="bg-white rounded-lg shadow-md p-6">
            <h1 className="text-2xl font-semibold mb-4">{num_pedido}</h1>
            <h2 className="text-lg font-semibold mb-4">Detalles del Pedido</h2>
            <div className="overflow-auto max-h-96">
                <table className="table-auto w-full">
                    <thead>
                        <tr className="bg-pink-600 text-white">
                            <th className="px-4 py-2">Descripción</th>
                            <th className="px-4 py-2">Código</th>
                            <th className="px-4 py-2">Unidades Pedidas</th>
                            <th className="px-4 py-2">Unidades Servidas</th>
                            <th className="px-4 py-2">Importe</th>
                            <th className="px-4 py-2">Estado</th>
                        </tr>
                    </thead>
                    <tbody>
                        {pedido && pedido.map((line, index_line) => (
                            <tr key={index_line} className={index_line % 2 === 0 ? 'bg-gray-100' : 'bg-white'}>
                                <td className="border px-4 py-2">{line.DESCRIPCION}</td>
                                <td className="border px-4 py-2">{line.COD_ARTICULO}</td>
                                <td className="border px-4 py-2">{line.UD_PEDIDAS}</td>
                                <td className="border px-4 py-2">{line.UD_SERVIDAS}</td>
                                <td className="border px-4 py-2">{line.IMPORTE} €</td>
                                <td className="border px-4 py-2">{line.ESTADO}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    )
}
const mapStateToProps = state => ({
    pedido: state.orders.order_lines,
    lan: state.auth.user?.idioma,
    pool: state.auth.user?.default_poolid
})

export default connect(mapStateToProps, {
    get_order
})(OrderCardHorizontal)
