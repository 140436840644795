import {
    ADD_TO_CART,
    DELETE_ALL_FROM_CART,
    DELETE_PRODUCT_FROM_CART,
    CALCULATE_TOTAL_PRICE_OF_THE_CART,
    INCREMENT_QUANTITY,
    DECREMENT_QUANTITY
} from '../actions/Cart/types';

const initialState = {
    // products: [],
    cart: [],
    totalPriceShoppingCart: 0
};

export const cart = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case ADD_TO_CART:
            return {
                ...state,
                cart: payload.cart,
                totalPriceShoppingCart: payload.totalPriceShoppingCart
            };
        case DELETE_ALL_FROM_CART: {
            return {
                ...state,
                cart: [],
                totalPriceShoppingCart: 0
            };
        }
        case DELETE_PRODUCT_FROM_CART: {
            return {
                ...state,
                cart: action.payload
            };
        }
        case INCREMENT_QUANTITY: {
            // Incrementa la cantidad del producto en el carrito
            const updatedCart = state.cart.map(item => {
                if (item.COD_ARTICULO === payload) {
                    return {
                        ...item,
                        cantidad: item.cantidad
                    };
                }
                return item;
            });
            return {
                ...state,
                cart: updatedCart
            };
        }
        case DECREMENT_QUANTITY: {
            // Decrementa la cantidad del producto en el carrito, asegurando que no sea menos de 0
            const updatedCart = state.cart.map(item => {
                if (item.COD_ARTICULO === payload) {
                    return {
                        ...item,
                        cantidad: Math.max(item.cantidad, 0)
                    };
                }
                return item;
            });
            return {
                ...state,
                cart: updatedCart
            };
        }
        case CALCULATE_TOTAL_PRICE_OF_THE_CART: {
            // Aquí deberías implementar la lógica para calcular el precio total del carrito
            return state;
        }
        default:
            return state;
    }
};
