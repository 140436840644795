import OrderCardHorizontal from "./OrderCardHorizontal"
import { useState } from "react"

function OrdersList({ orders, searchTermLower }) {

  const [sortConfig, setSortConfig] = useState({ key: 'FECHA_PEDIDO', direction: 'desc' });

  const parseDate = (dateString) => {
    const [day, month, year] = dateString.split('/');
    return new Date(`${year}-${month}-${day}`);
}

const handleSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
        direction = 'desc';
    }
    setSortConfig({ key, direction });
}

const sortedOrders = orders ? [...orders]
        .sort((a, b) => {
            if (sortConfig.key === 'FECHA_PEDIDO') {
                const dateComparison = parseDate(b.FECHA_PEDIDO) - parseDate(a.FECHA_PEDIDO);
                if (dateComparison !== 0) return sortConfig.direction === 'asc' ? -dateComparison : dateComparison;
            } else if (sortConfig.key === 'NUM_PEDIDO') {
                const numComparison = b.NUM_PEDIDO.localeCompare(a.NUM_PEDIDO, undefined, { numeric: true });
                if (numComparison !== 0) return sortConfig.direction === 'asc' ? -numComparison : numComparison;
            } else {
                const fieldComparison = a[sortConfig.key].localeCompare(b[sortConfig.key]);
                return sortConfig.direction === 'asc' ? fieldComparison : -fieldComparison;
            }
            return 0;
        }) : [];

  return (
    <div className="max-h-[60vh] overflow-auto hide-scrollbar"> {/* Aplicando max-h-64 al contenedor */}
      <table className="w-full text-sm text-left text-gray-800 dark:text-gray-800 justify-center">
        <thead className="sticky top-0 text-xs uppercase bg-pink-600 text-white text-center z-10">
          <tr>
            <th scope="col" className="py-3 px-4 uppercase font-semibold text-sm cursor-pointer">
            </th>
            <th onClick={() => handleSort('NUM_PEDIDO')} scope="col" className="w-1/5 py-3 px-4 uppercase font-semibold text-sm cursor-pointer">
              Número de Pedido {sortConfig.key === 'NUM_PEDIDO' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
            </th>
            <th onClick={() => handleSort('FECHA_PEDIDO')} scope="col" className="w-1/5 py-3 px-4 uppercase font-semibold text-sm cursor-pointer">
              Fechas {sortConfig.key === 'FECHA_PEDIDO' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
            </th>
            <th onClick={() => handleSort('ESTADO')} scope="col" className="w-1/5 py-3 px-4 uppercase font-semibold text-sm cursor-pointer">
              Estado {sortConfig.key === 'ESTADO' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
            </th>
            <th scope="col" className="w-1/5 py-3 px-4 uppercase font-semibold text-sm cursor-pointer">
              Total
            </th>
            <th scope="col" className="w-1/5 py-3 px-4 uppercase font-semibold text-sm cursor-pointer">
              Documentos
            </th>
          </tr>
        </thead>
        <tbody>
          {sortedOrders && sortedOrders.map((order, index) => (
            <OrderCardHorizontal data={order} key={index} index={index} />
          ))}
        </tbody>
      </table>
    </div>
  )
}
export default OrdersList
