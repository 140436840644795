import React, { useEffect } from 'react';

const InfinityLoader = ({ onFinish }) => {

    useEffect(() => {
        const timer = setTimeout(() => {
            onFinish();
        }, 1000); // Dura 5 segundos como la animación del SVG

        return () => clearTimeout(timer);
    }, [onFinish]);

    return (
        <div className="flex  justify-center items-center bg-transparent h-full w-full">
            <svg width="1000" height="300" viewBox="0 0 250 150" xmlns="http://www.w3.org/2000/svg">
                <path id="infinity-path" d="M100,65 C70,25 30,45 30,75 C30,105 70,125 110,75 C150,25 190,45 190,75 C190,105 150,125 120,85"
                    fill="none" stroke="#DB2777" strokeWidth="10"/>
                <style>
                    {`
                        #infinity-path {
                            stroke-dasharray: 950;
                            stroke-dashoffset: 950;
                            animation: draw-infinity 4s linear infinite;
                        }
                        @keyframes draw-infinity {
                            0% {
                                stroke-dashoffset: 1000;
                            }
                            50% {
                                stroke-dashoffset: 0;
                            }
                            51% {
                                stroke-dashoffset: 0;
                            }
                            100% {
                                stroke-dashoffset: -1000;
                            }
                        }
                    `}
                </style>
            </svg>
        </div>
    );
};

export default InfinityLoader;
