import { connect } from "react-redux";
import React, { useLayoutEffect , useRef, useContext } from "react";
import { HeightContext } from "components/utils/HeightContext";
import LogoInfinity from "assets/img/Infinity3_logo.png";

function Footer() {
  const footerRef = useRef(null);
  const { setFooterHeight, recalculateHeights } = useContext(HeightContext);

  useLayoutEffect (() => {
    if (footerRef.current) {
      setFooterHeight(footerRef.current.clientHeight);
    }
    recalculateHeights(); // Asegura recalcular las alturas cuando se monta el componente
  }, [footerRef, setFooterHeight, recalculateHeights]);

  return (
    <div
      ref={footerRef}
      className="footer-class absolute w-full bottom-0 items-center text-justify py-3 z-50 bg-white bg-opacity-95 border-t-2 border-pink-400 rounded-t-lg"
    >
      <div className="flex justify-center items-center space-x-4">
        <img className="h-auto w-14" src={LogoInfinity} alt="Infinity3" />
        <span className="text-gray-900 text-opacity-60 font-raleway font-semibold text-lg">
          Copyright
        </span>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({});

export default connect(mapStateToProps, {})(Footer);
